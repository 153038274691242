import React from 'react';
import * as sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

function sanitizeForReact(dirty: string, options?: sanitizeHtml.IOptions) {
  return {
    __html: sanitizeHtml.default(dirty, { ...defaultOptions, ...(options ?? {}) }),
  };
}

export function SanitizeHtml({ children, options }: { children: string; options?: sanitizeHtml.IOptions }) {
  return <div dangerouslySetInnerHTML={sanitizeForReact(children, options)} />;
}
