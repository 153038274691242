import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../common/index';
import { AppContext } from '../../../common/contexts/AppContext';
import { ClientCustomerEntity, ClientProjectEntity, ClientRemoteServiceEntity, IContentSyncApi } from '@edgebox/api-rest-client';
import { CONTENT_CLOUD_API_VERSION } from './content-cloud-helper';
import { PublisherClient } from './PublisherClient';
import { Permission } from './shared-permissions';
import { LoadingBar } from '@edgebox/react-components';

export interface ContentCloudComponentData {
  contentCloud: ClientRemoteServiceEntity;

  space: { id: string; domainKey: string };
  environment: { id: string; domainKey: string };

  accessToken: string;
}

export async function updateAccessToken(
  api: IContentSyncApi,
  { environment, space, contentCloud }: ContentCloudComponentData,
  permissions: Permission[]
) {
  return await api.syndication.remoteServices.getRequestToken(contentCloud.id, permissions, {
    environmentIds: [environment.id],
    spaceId: space.id,
  });
}

export async function initContentCloud(
  api: IContentSyncApi,
  contentCloud: ClientRemoteServiceEntity,
  permissions: Permission[],
  project: ClientProjectEntity,
  customer?: ClientCustomerEntity
): Promise<ContentCloudComponentData> {
  const accessToken = await api.syndication.remoteServices.getRequestToken(contentCloud.id, [
    Permission.ORGANIZATION_READ,
    Permission.SPACE_READ,
  ]);

  const client = new PublisherClient({
    baseUrl: contentCloud.baseUrl,
    version: CONTENT_CLOUD_API_VERSION,
    accessToken,
  });

  if (!customer) {
    customer = await project.customer.get();
  }

  const organization = await client.getOrganization(customer.uuid);
  const space = await client.getSpace(organization.id, project.uuid);

  const environment = space.defaultEnvironment;

  const clientAccessToken = await api.syndication.remoteServices.getRequestToken(contentCloud.id, permissions, {
    environmentIds: [environment.id],
    spaceId: space.id,
  });

  return {
    contentCloud,
    space,
    environment,
    accessToken: clientAccessToken,
  };
}

export function WithContentCloud(props: { children: (data: ContentCloudComponentData) => React.ReactNode; permissions: Permission[] }) {
  const api = useContext(ApiContext);
  const appContext = useContext(AppContext);

  let { customer, project } = appContext;

  const [contentCloud, setContentCloud] = useState<ClientRemoteServiceEntity | null>(null);
  const [space, setSpace] = useState<{ id: string; domainKey: string } | null>(null);
  const [environment, setEnvironment] = useState<{ id: string; domainKey: string } | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    if (!customer || !project || !api || !project.syncCore) {
      return;
    }

    project.syncCore?.get().then(async (contentCloud) => {
      if (!contentCloud) {
        return;
      }

      setContentCloud(contentCloud);

      const { accessToken, environment, space } = await initContentCloud(api, contentCloud, props.permissions, project, customer);

      setSpace(space);

      setEnvironment(environment);

      setAccessToken(accessToken);
    });
  }, [api, customer, project, props.permissions]);

  if (!api || !appContext) {
    return null;
  }

  if (!space || !environment || !contentCloud || !accessToken) {
    return <LoadingBar />;
  }

  return props.children({
    contentCloud,
    space,
    environment,
    accessToken,
  });
}

export function withContentCloud<P extends ContentCloudComponentData>(
  Component: React.FunctionComponent<P>,
  permissions: Permission[]
): React.FunctionComponent<Omit<P, keyof ContentCloudComponentData>> {
  return function ComponentWithContentCloud(otherProps: Omit<P, keyof ContentCloudComponentData>) {
    return (
      <WithContentCloud permissions={permissions}>
        {(data: ContentCloudComponentData) => (<Component {...({ ...data, ...otherProps } as P)} />) as React.ReactNode}
      </WithContentCloud>
    );
  };
}
