import { Badge } from 'react-bootstrap';
import { EntrySystemMetadata } from '../RestClient';
import React from 'react';

type Props = {
  sys: EntrySystemMetadata;
};

export function EntryPublishedBadge({ sys }: Props) {
  return sys.isPublished ? (
    <Badge bg="success">Published</Badge>
  ) : sys.firstPublishedAt ? (
    <Badge bg="info">Changed</Badge>
  ) : (
    <Badge bg="warn">Draft</Badge>
  );
}
