import { ClientRemoteServiceEntity } from '@edgebox/api-rest-client';
import { EditButton, LeftRightContainer } from '@edgebox/react-components';
import React from 'react';
import { Alert, Badge, Button, Col, Image, Row } from 'react-bootstrap';
import { IIdParamProp, withIdParam } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import SyncCoreDetailImageDisconnected from '../../../images/undraw_server_down_s4lk.svg';
import SyncCoreDetailImage from '../../../images/undraw_server_status_5pbv.svg';
import { RemoteServiceStatus } from '@edgebox/sync-core-data-definitions';
import { IApiComponentState, IItemProps, ApiComponent, SyncCoreForm, SyncCoreDataDefinitionsEnumValue } from '../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';

interface IExternalProps extends IItemProps {}
interface IProps extends IExternalProps, IIdParamProp {}

interface IState extends IApiComponentState {
  syncCore?: ClientRemoteServiceEntity;
  editingSyncCore?: ClientRemoteServiceEntity;
  submitting?: boolean;
}

class SyncCoreDetailComponent extends ApiComponent<IProps, IState> {
  async load() {
    const syncCore = await this.api.syndication.remoteServices.item(this.props.id);

    return {
      syncCore,
    };
  }

  render() {
    const { syncCore, editingSyncCore, submitting } = this.state;

    if (!syncCore) {
      return this.renderRequest();
    }

    return (
      <>
        <LeftRightContainer
          left={
            <HeadlineWithBreadcrumbNavigation>
              <span className="text-truncate mw-100 d-inline-block" title={syncCore.name}>
                {syncCore.name}
              </span>{' '}
              <Badge bg={syncCore.status === RemoteServiceStatus.Active ? 'success' : 'danger'} className={'small'}>
                <SyncCoreDataDefinitionsEnumValue enumName={'RemoteServiceStatus'} keyName={syncCore.status} />
              </Badge>
            </HeadlineWithBreadcrumbNavigation>
          }
          right={
            <>
              <EditButton
                onClick={() => {
                  const editingSyncCore = new ClientRemoteServiceEntity(syncCore);
                  this.setState({ editingSyncCore });
                }}
              />
            </>
          }
        />

        <Alert variant="warning">
          <strong>Don't use the same Sync Core UUID and secret for two different setups!</strong> This can lead to all kinds of
          hard-to-debug issues. Using the same Sync Core UUID and secret for your production environment and any other environment can lead
          to the production environment shutting down completely due to how license validation and abuse detection works.
        </Alert>

        <ContentBox>
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={syncCore.lastActivity ? SyncCoreDetailImage : SyncCoreDetailImageDisconnected} width={'100%'} />
            </Col>
            <Col className={'pt-4'}>
              <SyncCoreForm
                key={editingSyncCore ? 'editing' : 'viewing'}
                name={editingSyncCore ? undefined : <></>}
                onSave={async (entity) => {
                  this.setState({ submitting: true });
                  try {
                    const updated = await this.api.syndication.remoteServices.update(entity);
                    this.setState({
                      editingSyncCore: undefined,
                      syncCore: updated,
                    });
                  } finally {
                    this.setState({ submitting: undefined });
                  }
                }}
                mode={editingSyncCore ? 'edit-full' : 'view-full'}
                entity={editingSyncCore || syncCore}
              >
                {editingSyncCore && (
                  <Button disabled={submitting} variant="primary" type="submit">
                    Save
                  </Button>
                )}
              </SyncCoreForm>
            </Col>
          </Row>
        </ContentBox>
      </>
    );
  }
}

export const SyncCoreDetail = withIdParam(propertyChangeReload(SyncCoreDetailComponent, (props) => props.id));
