import {
  ClientContractEntity,
  ClientContractRevisionEntity,
  ClientSiteDraft,
  ClientSiteEntity,
  IInternalAuthentication,
} from '@edgebox/api-rest-client';
import {
  ClientError,
  ClientErrorType,
  DynamicReference,
  EnumProperty,
  NameProperty,
  StaticReference,
  UnformattedTextProperty,
  UrlProperty,
  Uuid,
} from '@edgebox/data-definition-kit';
import { CustomerRole } from '@edgebox/data-definitions';
import { AppConfiguration, IAppConfiguration, Right } from '@edgebox/react-components';
import { Package, SiteApplicationType, SiteEnvironmentType, SiteStatus } from '@edgebox/sync-core-data-definitions';
import moment from 'moment';
import React from 'react';
import { Alert, Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { IQueryParamsProp, withQueryParams } from '../../QueryParams';
import { ContentBox } from '../../Shared/ContentBox';
import RegisterNewSiteImage from '../../../images/undraw_join_of2w.svg';
import ConnectExistingSiteImage from '../../../images/undraw_blooming_jtv6.svg';
import { StartTrial } from '../../Shared/StartTrial';
import { Navigate } from 'react-router-dom';
import {
  ApiComponent,
  AuthenticationContext,
  IApiComponentState,
  IDataComponentConfiguration,
  Logo,
  NULL_ID,
  SiteForm,
} from '../../../common';
import { siteMustBeLocal } from '../../../common/helpers';
import { Link } from 'react-router-dom';

enum BooleanEnum {
  True = 'true',
  False = 'false',
}

class Params {
  @NameProperty()
  name!: string;

  @EnumProperty(true, SiteApplicationType, 'SiteApplicationType')
  appType!: SiteApplicationType;

  @UnformattedTextProperty(true, 32)
  appVersion!: string;

  @UnformattedTextProperty(true, 32)
  appModuleVersion!: string;

  @UrlProperty(true, false, { allowLocalhost: true })
  baseUrl!: string;

  @UrlProperty(false, false, { allowLocalhost: true })
  redirectUrl?: string;

  @UnformattedTextProperty(false, 48)
  uuid?: Uuid;

  @UnformattedTextProperty(false, 10_000)
  domains?: string;

  @EnumProperty(false, BooleanEnum, 'BooleanEnum')
  startNew?: string;
}

function getDomains(baseUrl: string, domains?: string): string[] {
  let result = [baseUrl.replace(/^https?:\/\/([^/]+).*$/, '$1'), ...(domains ? domains.split(',') : [])];
  result = result.filter((c, i) => c && result.indexOf(c) === i);
  return result;
}

interface IExternalProps {}
interface IProps extends IExternalProps, IQueryParamsProp<Params> {}

interface IState extends IApiComponentState {
  draft?: ClientSiteDraft;
  existingSiteByUuid?: ClientSiteEntity;
  existingSiteByBaseUrl?: ClientSiteEntity;
  redirectUrl?: string;
  redirect?: string;
  newTrial?: boolean;
  submitting?: boolean;
  isNewTrial?: boolean;
  purchase?: {
    agreed?: boolean;
    contract: ClientContractEntity;
    revision: ClientContractRevisionEntity;
    site: ClientSiteDraft;
  };
}

class RegisterSiteInternal extends ApiComponent<IProps, IState> {
  async load() {
    const customer = await this.getCurrentCustomer(true);

    const { params } = this.props;
    const { name, baseUrl, appModuleVersion, appType, appVersion, uuid, domains } = params;
    const draft = new ClientSiteDraft({
      name,
      baseUrl,
      appVersion,
      appModuleVersion,
      appType,
      environmentType: undefined as any,
      status: SiteStatus.Active,
      customer: new StaticReference(customer),
      project: new DynamicReference(NULL_ID, this.api.syndication.projects.item),
      lastActivity: moment(),
      uuid: '' as Uuid,
      contract: new DynamicReference(NULL_ID, this.api.billing.contracts.item),
      syncCore: new DynamicReference(NULL_ID, this.api.syndication.remoteServices.item),
      domains: getDomains(baseUrl, domains),
    });

    let existingSiteByUuid: ClientSiteEntity | undefined = undefined;
    if (uuid && uuid !== 'undefined') {
      try {
        existingSiteByUuid = await this.api.billing.sites.itemByUuid(uuid);
      } catch (e) {
        if (!(e instanceof ClientError) || e.type !== ClientErrorType.NotFound) {
          throw e;
        }
      }
    }
    const existing = await this.api.billing.sites.search(
      undefined,
      { userOwnerId: siteMustBeLocal(baseUrl) ? this.api.currentUser!.id : undefined },
      { baseUrl, includeInactive: true }
    );
    const existingSiteByBaseUrl = existing.items.find((c) => c.status === SiteStatus.Active) ?? existing.items[0];

    // Get the first active contract to see if there are any. If there are
    // none, we can then check if the user is allowed to start a new one and
    // if they are, we show the <StartTrial>. Otherwise we show an error.
    const allContracts = await this.api.billing.contracts.search(
      undefined,
      { itemsPerPage: 1 },
      this.api.currentUser!.customer!.getId()!,
      false
    );
    if (allContracts.items.length) {
      draft.contract = new StaticReference(allContracts.items[0]);
    }

    return {
      existingSiteByUuid,
      existingSiteByBaseUrl,
      draft,
    };
  }

  render() {
    const { params } = this.props;
    const { draft, redirect, existingSiteByUuid, existingSiteByBaseUrl, newTrial, submitting, purchase, isNewTrial } = this.state;
    if (!draft) {
      return this.renderRequest();
    }

    if (redirect) {
      if (redirect.includes('//')) {
        return (
          <AppConfiguration.Consumer>
            {(context: IAppConfiguration<IDataComponentConfiguration>) => {
              return (
                <AuthenticationContext.Consumer>
                  {(auth: IInternalAuthentication) => {
                    window.location.href = `//${context.apiDomain}/site/${
                      (existingSiteByUuid || existingSiteByBaseUrl)!.id
                    }/registered-redirect?jwt=${encodeURIComponent(auth.jwt!)}&redirectUrl=${encodeURIComponent(redirect)}`;
                    return null;
                  }}
                </AuthenticationContext.Consumer>
              );
            }}
          </AppConfiguration.Consumer>
        );
      }
      return <Navigate to={redirect} />;
    }

    const renderExisting = (existingSite: ClientSiteEntity) => {
      return (
        <>
          <h1>
            Register site{' '}
            <em className="text-truncate mw-100 d-inline-block align-bottom" title={draft.name}>
              {draft.name}
            </em>
          </h1>

          <ContentBox>
            <Row>
              <Col xs={6} className={'p-5'}>
                <Image src={ConnectExistingSiteImage} width={'100%'} />
              </Col>
              <Col className={'pe-5 pb-5'}>
                <Alert variant={'light'}>You have already registered this site.</Alert>

                <SiteForm
                  key={existingSite.baseUrl}
                  className={'mt-4'}
                  name={<></>}
                  onSave={async () => {}}
                  entity={existingSite}
                  mode={'registered'}
                >
                  {existingSite.baseUrl !== this.props.params.baseUrl ? (
                    <>
                      <Alert variant="danger">
                        You have already registered this site with a different base URL.
                        <ul>
                          <li>
                            If you have setup a <strong>new site</strong> with a database of a previous site, please select{' '}
                            <em>Register new site</em> below.
                          </li>
                          <li>
                            If the base URL of an <strong>existing site</strong> changed instead, please select <em>Update base URL</em>{' '}
                            below.
                          </li>
                        </ul>
                      </Alert>
                      <Right>
                        <Button
                          disabled={submitting}
                          variant="danger"
                          onClick={() => {
                            this.setState({
                              existingSiteByUuid: undefined,
                            });
                          }}
                        >
                          Register new site
                        </Button>
                        <Button
                          disabled={submitting}
                          variant="danger"
                          onClick={async () => {
                            this.setState({ submitting: true });

                            try {
                              existingSite.baseUrl = this.props.params.baseUrl;
                              const updated = await this.api.billing.sites.update(existingSite);

                              this.setState({
                                existingSiteByUuid: updated,
                              });
                            } finally {
                              this.setState({
                                submitting: false,
                              });
                            }
                          }}
                        >
                          Update base URL
                        </Button>
                      </Right>
                    </>
                  ) : existingSite.status === SiteStatus.Inactive ? (
                    <Right>
                      <Button
                        disabled={submitting}
                        variant="danger"
                        onClick={() => {
                          this.setState({
                            existingSiteByUuid: undefined,
                            existingSiteByBaseUrl: undefined,
                          });
                        }}
                      >
                        Register new site
                      </Button>
                      <Button
                        disabled={submitting}
                        variant="danger"
                        onClick={async () => {
                          this.setState({ submitting: true });

                          try {
                            existingSite.status = SiteStatus.Active;
                            const updated = await this.api.billing.sites.update(existingSite);

                            this.setState({
                              existingSiteByUuid: existingSiteByUuid === existingSite ? updated : undefined,
                              existingSiteByBaseUrl: existingSiteByBaseUrl === existingSite ? updated : undefined,
                            });
                          } finally {
                            this.setState({
                              submitting: false,
                            });
                          }
                        }}
                      >
                        Re-activate
                      </Button>
                    </Right>
                  ) : (
                    <Right>
                      <Button
                        onClick={() => {
                          this.setState({
                            redirect: params.redirectUrl || `/sites/${existingSite.id}`,
                          });
                        }}
                      >
                        Continue
                      </Button>
                    </Right>
                  )}
                </SiteForm>
              </Col>
            </Row>
          </ContentBox>
        </>
      );
    };

    if (existingSiteByUuid) {
      return renderExisting(existingSiteByUuid);
    }
    if (existingSiteByBaseUrl) {
      return renderExisting(existingSiteByBaseUrl);
    }

    const requiredLicenses = draft.domains!.length;

    const canPurchaseNew =
      this.api.currentUser?.customerRoles?.includes(CustomerRole.Owner) ||
      this.api.currentUser?.customerRoles?.includes(CustomerRole.ManageFinances);

    const canQuickBuy =
      purchase?.contract.autoScaleLicenses &&
      (!purchase?.contract.maxProdSites || purchase.contract.currentProductionSites + requiredLicenses <= purchase.contract.maxProdSites);

    const startNew = draft.contract.getId() === NULL_ID || newTrial;

    return (
      <>
        <h1>
          Register site{' '}
          <em className="text-truncate mw-100 d-inline-block align-bottom" title={draft.name}>
            {draft.name}
          </em>
        </h1>

        <ContentBox>
          <Row>
            {!startNew && (
              <Col xs={12} sm={12} md={12} xl={6} xxl={6} className={'d-xs-none d-sm-none d-md-none d-xl-none d-xxl-block p-4'}>
                <Image src={RegisterNewSiteImage} width={'100%'} />
              </Col>
            )}
            <Col className={'pe-4 pb-4'}>
              {startNew ? (
                <div>
                  {canPurchaseNew ? (
                    <StartTrial
                      forceNew={newTrial}
                      forCustomer={draft.customer.getId()}
                      onChange={(contract, isInitial) => {
                        draft.contract = new StaticReference(contract);
                        this.setState({ draft, newTrial: undefined, isNewTrial: !isInitial });
                      }}
                      numberOfSites={requiredLicenses}
                    />
                  ) : (
                    <Alert variant="warning">
                      There's no active subscription and you don't have the required permissions to start a new subscription. Please ask the
                      owner of your account to start a new subscription or to grant you permission to do so.
                    </Alert>
                  )}
                </div>
              ) : (
                <SiteForm
                  className={'mt-4 site-registration-form'}
                  name={<></>}
                  onSave={async (site) => {
                    this.setState({ submitting: true });
                    try {
                      const contract = await site.contract.get();
                      // If this is a trial, we purchase another license silently as the user isn't paying for it anyway.
                      let autoPurchase = false;
                      // Must purchase new licenses before the site can be registered.
                      if (
                        site.environmentType === SiteEnvironmentType.Production &&
                        contract.currentProductionSites >= contract.licensedSites
                      ) {
                        const mostRecent = await this.api.billing.contractRevisions.getMostRecentForContract(contract.id);
                        if (mostRecent.current?.packageType === Package.Trial || purchase?.agreed) {
                          autoPurchase = true;
                        } else {
                          this.setState({
                            purchase: {
                              contract,
                              site,
                              revision: mostRecent.current!,
                            },
                          });
                          return;
                        }
                      }

                      const entity = autoPurchase ? await this.api.billing.sites.quickBuy(site) : await this.api.billing.sites.create(site);
                      this.setState({
                        existingSiteByUuid: entity,
                        existingSiteByBaseUrl: undefined,
                        redirect: params.redirectUrl || `/sites/${entity.id}`,
                      });
                    } finally {
                      this.setState({ submitting: false });
                    }
                  }}
                  entity={draft}
                  mode={'register'}
                >
                  <Right>
                    {!isNewTrial && canPurchaseNew && this.props.params.startNew === BooleanEnum.True && (
                      <Button onClick={() => this.setState({ newTrial: true })} variant="light">
                        Start new subscription
                      </Button>
                    )}
                    <Button disabled={submitting} id="continue" type={'submit'}>
                      Continue
                    </Button>
                  </Right>
                </SiteForm>
              )}
            </Col>
          </Row>
        </ContentBox>

        <div className="text-center pt-4 pb-3">
          <Link to={'/'}>
            <Logo height={40} />
          </Link>
        </div>

        {purchase && !purchase.agreed && (
          <Modal show={true} scrollable onHide={() => this.setState({ purchase: undefined })}>
            <Modal.Header>
              <Modal.Title>Purchase required</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant={canQuickBuy ? 'light' : 'warning'}>
                You have hit the limit of licensed sites for your subscription.
                <br />
                <br />
                {purchase.contract.autoScaleLicenses
                  ? canQuickBuy
                    ? `You will be charged for ${requiredLicenses} additional site(s) per month if you continue. You will be charged partially for the remainder of your current contract term.`
                    : `Your subscription is set to auto scale licenses but you hit the limit of ${purchase.contract.maxProdSites} licenses. Please either increase the limit or manually buy ${requiredLicenses} new license(s) and try again.`
                  : `Your subscription is not set to auto scale licenses so you have to purchase one more license manually and try again.`}
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Right>
                {canQuickBuy && (
                  <Button
                    id="purchase"
                    disabled={submitting}
                    onClick={() => {
                      this.setState({ purchase: { ...purchase, agreed: true } });
                      const button = document.getElementById('continue') as HTMLButtonElement;
                      button.click();
                    }}
                  >
                    Buy now
                  </Button>
                )}
              </Right>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export const RegisterSite = withQueryParams<IExternalProps, Params>(RegisterSiteInternal, Params);
