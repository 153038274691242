import { ClientSiteEntity, ClientRemoteServiceEntity } from '@edgebox/api-rest-client';
import { ExternalLink, LeftRightContainer, Right } from '@edgebox/react-components';
import React from 'react';
import { Alert, Button, Col, Modal, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { ApiComponent, FormatDateTime, IApiComponentState } from '../../../common/index';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../RouterHelper';
import { ContentBox } from '../../Shared/ContentBox';
import { ProjectStatus, SiteStatus } from '@edgebox/sync-core-data-definitions';
import { CustomerRole, UserType } from '@edgebox/data-definitions';
import { RemoteServiceHostingType } from '@edgebox/sync-core-data-definitions';
import moment from 'moment';
import { faFingerprint } from '@fortawesome/pro-light-svg-icons/faFingerprint';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faGlobePointer } from '@fortawesome/pro-light-svg-icons/faGlobePointer';
import { DateTimeFormat, InternalId } from '@edgebox/data-definition-kit';
import { Embed } from '../../Shared/Embed';
import { GraphStats } from '../Stats/GraphStats';
import { HeatmapStats } from '../Stats/HeatmapStats';
import { PagedLogItemList } from '../../Backend/PagedLogItemList';
import { WithPromise } from '../../../common/helpers/WithPromise';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { propertyChangeReload } from '../../../common/helpers/propertyChangeReload';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';
import { REMOTE_SERVICE_HOSTING_TYPE_TYPE_ICONS, SiteIcons } from '../../Icons';
import { Nugget } from '../../../common/components/Nugget';
import { urlSearchParamsToObject } from '../../../common/helpers';
import { UserRoleGate } from '../../../common/components/UserRoleGate';

interface IExternalProps {}
interface IProps extends JSX.IntrinsicAttributes, IExternalProps, IAppContextProp, INavigateProp, ILocationProp {
  params: {
    tab: string;
    site: InternalId;
  };
}

interface IState extends IApiComponentState {
  site?: ClientSiteEntity;
  confirmAction?: 'deactivate' | 'reactivate';
  submitted?: boolean;
  syncCore?: ClientRemoteServiceEntity;
}

class SiteDetailComponent extends ApiComponent<IProps, IState> {
  async load() {
    const site = await this.api.billing.sites.item(this.props.params.site);
    const syncCore = await site.syncCore.get();

    this.props.appContext.setSite?.(site);

    return {
      site,
      syncCore,
    };
  }

  render() {
    const { site, confirmAction, submitted, syncCore } = this.state;
    const { tab } = this.props.params;

    if (!site) {
      return this.renderRequest();
    }

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal;

    const canViewContent =
      renderBackendProperties ||
      !!this.api.currentUser?.customerRoles?.find((c) =>
        [CustomerRole.Owner, CustomerRole.ManageSites, CustomerRole.ManageContent].includes(c)
      );

    const canViewConfig =
      renderBackendProperties ||
      !!this.api.currentUser?.customerRoles?.find((c) => [CustomerRole.Owner, CustomerRole.ManageSites].includes(c));

    return (
      <>
        <LeftRightContainer
          className="mb-4"
          left={
            <HeadlineWithBreadcrumbNavigation className="mb-0">
              <div className="text-truncate mw-100">
                <SiteIcons site={site} />
                <span className="ms-3" title={site.name}>
                  {site.name}
                </span>
              </div>
              <div className="fs-6 mt-1 fw-normal">
                <Nugget icon={faLink}>
                  <ExternalLink to={site.baseUrl} className="text-dark">
                    {site.baseUrl}
                  </ExternalLink>
                </Nugget>
                <Nugget icon={REMOTE_SERVICE_HOSTING_TYPE_TYPE_ICONS[syncCore?.type || RemoteServiceHostingType.SaaS]}>
                  {syncCore?.name}
                </Nugget>
                <Nugget icon={faFingerprint}>{site.uuid}</Nugget>
                <Nugget icon={faClock}>
                  <FormatDateTime date={site.lastActivity} format={DateTimeFormat.Date} />
                </Nugget>
                {site.domains?.length && site.domains.length > 1 && (
                  <Nugget icon={faGlobePointer}>
                    <OverlayTrigger
                      placement="left"
                      overlay={(props) => (
                        <Tooltip id={`tooltip-domains`} {...props} className="tooltip-wide">
                          <ul>
                            {site.domains!.map((c) => (
                              <li key={c}>{c}</li>
                            ))}
                          </ul>
                        </Tooltip>
                      )}
                    >
                      <span>{site.domains.length}x</span>
                    </OverlayTrigger>
                  </Nugget>
                )}
              </div>
            </HeadlineWithBreadcrumbNavigation>
          }
          right={
            <UserRoleGate allowed={[CustomerRole.Owner, CustomerRole.ManageSites]}>
              {() => (
                <>
                  {site.status === SiteStatus.Active ? (
                    <Button variant="danger" onClick={() => this.setState({ confirmAction: 'deactivate' })}>
                      Deactivate
                    </Button>
                  ) : (
                    <WithPromise promise={site.project.get()}>
                      {(project) =>
                        project.status === ProjectStatus.Inactive ? (
                          <></>
                        ) : (
                          <Button variant="primary" onClick={() => this.setState({ confirmAction: 'reactivate' })}>
                            Reactivate
                          </Button>
                        )
                      }
                    </WithPromise>
                  )}
                </>
              )}
            </UserRoleGate>
          }
        />

        <Tabs
          id="content-tabs"
          activeKey={tab}
          onSelect={(activeTab) => this.props.navigate(`/projects/${site.project.getId()}/sites/${site.id}/${activeTab}`)}
        >
          <Tab eventKey="stats" title={'Stats'}>
            <ContentBox className="pt-3">
              <Row>
                <Col className="ps-0 pe-4" xs={12} sm={6}>
                  <h4>Daily updates</h4>
                  <ContentBox className={'pb-2'}>
                    <GraphStats displayType="bars" forSite={site} />
                  </ContentBox>
                </Col>
                <Col className="pe-0 ps-4" xs={12} sm={6}>
                  <h4>Heatmap</h4>
                  <ContentBox className={'pb-2'}>
                    <HeatmapStats forSite={site} />
                  </ContentBox>
                </Col>
              </Row>
            </ContentBox>
          </Tab>
          {canViewConfig && (
            <Tab eventKey="registration" title={'Registration'}>
              <ContentBox>
                {site.status === SiteStatus.Active ? (
                  <Embed
                    embed="site-registered"
                    scope="configuration"
                    syncCore={syncCore!}
                    site={site}
                    options={{
                      viewOnly: true,
                    }}
                  />
                ) : (
                  <Alert variant="light">Site is not active.</Alert>
                )}
              </ContentBox>
            </Tab>
          )}
          {canViewContent && (
            <Tab eventKey="content" title={'Content'}>
              <ContentBox>
                {site.status === SiteStatus.Active ? (
                  <Embed
                    embed="pull-dashboard"
                    scope="configuration"
                    syncCore={syncCore!}
                    site={site}
                    options={{
                      viewForSite: true,
                      configurationAccess: true,
                      query: urlSearchParamsToObject(new URLSearchParams(this.props.location.search)),
                    }}
                  />
                ) : (
                  <Alert variant="light">Site is not active.</Alert>
                )}
              </ContentBox>
            </Tab>
          )}
          {canViewContent && (
            <Tab eventKey="updates" title={'Updates'}>
              <ContentBox>
                {site.status === SiteStatus.Active ? (
                  <Embed
                    embed="syndication-dashboard"
                    scope="configuration"
                    syncCore={syncCore!}
                    site={site}
                    options={{
                      viewOnly: true,
                      query: urlSearchParamsToObject(new URLSearchParams(this.props.location.search)),
                    }}
                  />
                ) : (
                  <Alert variant="light">Site is not active.</Alert>
                )}
              </ContentBox>
            </Tab>
          )}
          {canViewConfig && (
            <Tab eventKey="settings" title={'Settings'}>
              <ContentBox>
                {site.status === SiteStatus.Active ? (
                  <Embed embed="site-settings" scope="configuration" syncCore={syncCore!} site={site} options={{}} />
                ) : (
                  <Alert variant="light">Site is not active.</Alert>
                )}
              </ContentBox>
            </Tab>
          )}
          {renderBackendProperties && (
            <Tab eventKey="logs" title={'Logs'}>
              <ContentBox className="pt-3">
                <PagedLogItemList id={`site-${site.id}`} siteId={site.id} />
              </ContentBox>
            </Tab>
          )}
        </Tabs>

        <Modal show={!!confirmAction} scrollable onHide={() => this.setState({ confirmAction: undefined })}>
          <Modal.Header closeButton>
            <Modal.Title>{confirmAction === 'deactivate' ? 'Deactivate site' : 'Reactivate site'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirmAction === 'deactivate' ? (
              <>
                <strong>Are you sure you want to deactivate this site?</strong>
                <br />
                <br />
                This site won't be able to push or pull content anymore and you won't be charged for this site in the future.
              </>
            ) : (
              <>
                <strong>Are you sure you want to reactivate this site?</strong>
                <br />
                <br />
                You will be charged again for this site with the next invoice. After reactivating the site please re-run the site
                registration and export all Flows.
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                disabled={submitted}
                variant={confirmAction === 'deactivate' ? 'danger' : 'primary'}
                onClick={async () => {
                  this.setState({ submitted: true });
                  try {
                    site.status = confirmAction === 'deactivate' ? SiteStatus.Inactive : SiteStatus.Active;
                    if (site.status === SiteStatus.Inactive) {
                      site.inactiveSince = moment();
                    }
                    const updatedSite = await this.api.billing.sites.update(site);
                    this.setState({ site: updatedSite, confirmAction: undefined });
                    this.props.appContext.refreshSites?.();
                  } finally {
                    this.setState({ submitted: false });
                  }
                }}
              >
                Continue
              </Button>
            </Right>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export const SiteDetail = withParams<{}, { site: string; tab: string }>(
  withLocationAndNavigate<any>(propertyChangeReload<any>(withAppContext<any>(SiteDetailComponent), (props) => props.site))
);
