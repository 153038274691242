import { useContext, useEffect, useMemo, useState } from 'react';
import { ContentCloudComponentData, initContentCloud, updateAccessToken } from '../WithContentCloud';
import { RestInterfaceDataTypes } from '../RestClient';
import { Permission } from '../shared-permissions';
import { getContentCloudSatelliteUrl } from '../content-cloud-helper';
import { ApiContext } from '../../../../common';
import { AppContext } from '../../../../common/contexts/AppContext';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import React from 'react';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton } from '@edgebox/react-components';

const ALLOWED_PERMISSIONS = Object.values(Permission).filter(
  (c) =>
    !c.includes('organization') && !c.includes('client') && (!c.includes('write') || c.includes('user-data')) && !c.includes('developer')
);

interface Props {}

const PERMISSIONS_PER_REST_INTERFACE_DATA_TYPE: { [key in RestInterfaceDataTypes]: Permission[] } = {
  space: [Permission.SPACE_READ],
  content_types: [Permission.SPACE_READ, Permission.CONTENT_TYPE_READ],
  entries: [Permission.SPACE_READ, Permission.CONTENT_READ, Permission.ASSET_READ_FILE],
  assets: [Permission.SPACE_READ, Permission.CONTENT_READ, Permission.ASSET_READ_FILE],
  tags: [Permission.SPACE_READ, Permission.CONTENT_READ],
  locales: [Permission.SPACE_READ],
};

class RestRequestSettings {
  constructor(properties?: Partial<RestRequestSettings>) {
    this.service = properties?.service ?? 'live';
    this.apiVersion = properties?.apiVersion ?? 'latest';
    this.entryType = properties?.entryType ?? 'entries';
    this.entryId = properties?.entryId;
    this.queryParameters = properties?.queryParameters ?? {};
    this.autoManagePermissions = properties?.autoManagePermissions ?? true;
    this.permissions = properties?.permissions ?? PERMISSIONS_PER_REST_INTERFACE_DATA_TYPE[this.entryType];
    this.accessToken = properties?.accessToken;
  }

  service: 'live' | 'cdn' | 'preview';
  apiVersion: string;

  entryType: RestInterfaceDataTypes;
  entryId?: string;

  queryParameters: Record<string, unknown>;

  autoManagePermissions: boolean;
  permissions: Permission[];

  accessToken?: string;
}

export default function RestExplorer({}: Props) {
  const api = useContext(ApiContext);
  const appContext = useContext(AppContext);

  const [requestSettings, setRequestSettings] = useState(new RestRequestSettings());
  const [contentCloudData, setContentCloudData] = useState<ContentCloudComponentData | null>(null);

  const [accessTokenCache, setAccessTokenCache] = useState({ accessToken: '', permissions: [] as Permission[] });

  useEffect(() => {
    if (!appContext.customer || !appContext.project || !api || !appContext.project.syncCore) {
      return;
    }

    appContext.project.syncCore?.get().then(async (contentCloud) => {
      if (!contentCloud) {
        return;
      }

      initContentCloud(api, contentCloud, [Permission.SPACE_READ], appContext.project!, appContext.customer).then((data) => {
        setContentCloudData(data);
      });
    });
  }, [api, appContext]);

  const [restUrl, setRestUrl] = useState('');
  const [response, setResponse] = useState<any>(null);
  useEffect(() => {
    (async () => {
      setResponse(null);

      if (!api || !contentCloudData) {
        return;
      }

      if (!requestSettings.permissions.length) {
        return;
      }

      let accessToken = requestSettings.accessToken ?? accessTokenCache.accessToken;
      if (!requestSettings.accessToken) {
        if (!accessToken || JSON.stringify(accessTokenCache.permissions.sort()) !== JSON.stringify(requestSettings.permissions.sort())) {
          accessToken = await updateAccessToken(api, contentCloudData, requestSettings.permissions);
          setAccessTokenCache({
            accessToken,
            permissions: requestSettings.permissions,
          });
        }
      }

      const response = await fetch(restUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setResponse(await response.json());
    })();
  }, [
    restUrl,
    requestSettings.permissions,
    requestSettings.accessToken,
    contentCloudData,
    api,
    setResponse,
    accessTokenCache,
    setAccessTokenCache,
  ]);

  const onChange = useMemo(
    () => () => {
      if (!contentCloudData) {
        return;
      }

      if (requestSettings.autoManagePermissions) {
        const newPermissions = [...PERMISSIONS_PER_REST_INTERFACE_DATA_TYPE[requestSettings.entryType]];
        if (requestSettings.service === 'preview') {
          newPermissions.push(Permission.PREVIEW);
        }

        if (JSON.stringify(requestSettings.permissions.sort()) !== JSON.stringify(newPermissions.sort())) {
          setRequestSettings(new RestRequestSettings({ ...requestSettings, permissions: newPermissions }));
        }
      }

      const baseUrl = getContentCloudSatelliteUrl(contentCloudData.contentCloud, {
        api: 'rest',
        service: requestSettings.service,
        version: requestSettings.apiVersion,
        environmentSubdomain: `${contentCloudData.space.domainKey}-${contentCloudData.environment.domainKey}`,
      });

      setRestUrl(`${baseUrl}/${requestSettings.entryType}${requestSettings.entryId ? `/${requestSettings.entryId}` : ''}`);
    },
    [contentCloudData, requestSettings]
  );
  useEffect(() => {
    onChange();
  }, [onChange]);

  const [updateRequestTimeout, setUpdateRequestTimeout] = useState(null as any);
  const updateRequest = useMemo(
    () =>
      (property: keyof RestRequestSettings, value: any, delay = false) => {
        (requestSettings as any)[property] = value;

        if (updateRequestTimeout) {
          clearTimeout(updateRequestTimeout);
        }

        if (delay) {
          setUpdateRequestTimeout(setTimeout(() => setRequestSettings(new RestRequestSettings(requestSettings)), 500));
        } else {
          setUpdateRequestTimeout(null);

          setRequestSettings(new RestRequestSettings(requestSettings));
        }
        //onChange();
      },
    [requestSettings, updateRequestTimeout]
  );

  const [sidebarTab, setSidebarTab] = useState('api');

  const prettyResponse = useMemo(() => JSON.stringify(response, null, 2), [response]);

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={<HeadlineWithBreadcrumbNavigation className="ms-2">REST Explorer</HeadlineWithBreadcrumbNavigation>}
        sidebar={
          <Tabs id={'sidebar-tabs'} activeKey={sidebarTab} onSelect={(k: string | null) => setSidebarTab(k ?? 'api')}>
            <Tab eventKey={'api'} title={'API'} className="p-3">
              <div className="mb-3">
                <div className="fw-bold">Service</div>
                <div>
                  <select onChange={(e) => updateRequest('service', e.target.value)} value={requestSettings.service}>
                    <option value="live">Live</option>
                    <option value="cdn">CDN</option>
                    <option value="preview">Preview</option>
                  </select>
                </div>
              </div>

              <div className="mb-3">
                <div className="fw-bold">API Version</div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => updateRequest('apiVersion', e.target.value, true)}
                    value={requestSettings.apiVersion}
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey={'entries'} title={'Entries'} className="p-3">
              <div className="mb-3">
                <div className="fw-bold">Type</div>
                <div>
                  <select onChange={(e) => updateRequest('entryType', e.target.value)} value={requestSettings.entryType}>
                    <option value="space">Space</option>
                    <option value="locales">Locales</option>
                    <option value="content_types">Content Types</option>
                    <option value="entries">Content</option>
                    <option value="assets">Assets</option>
                    <option value="tags">Tags</option>
                  </select>
                </div>
              </div>

              <div className="mb-3">
                <div className="fw-bold">Entry ID</div>
                <div>
                  <input
                    type="text"
                    onChange={(e) => updateRequest('entryId', e.target.value, true)}
                    value={requestSettings.entryId ?? ''}
                  />
                </div>
              </div>
            </Tab>

            <Tab eventKey={'access'} title={'Access'} className="p-3">
              <div className="mb-3">
                <div className="fw-bold">Permission management</div>
                <div>
                  <input
                    type="checkbox"
                    id="autoManagePermissions"
                    onChange={(e) => updateRequest('autoManagePermissions', e.target.checked)}
                    checked={requestSettings.autoManagePermissions}
                  />
                  <label htmlFor="autoManagePermissions">Apply automatically</label>
                </div>
              </div>

              <div className="mb-3">
                <div className="fw-bold">Permissions</div>
                {Object.values(ALLOWED_PERMISSIONS).map((permission) => (
                  <div key={permission}>
                    <input
                      type="checkbox"
                      disabled={requestSettings.autoManagePermissions}
                      id={`permission-${permission}`}
                      onChange={(e) =>
                        updateRequest(
                          'permissions',
                          e.target.checked
                            ? [...requestSettings.permissions, permission]
                            : requestSettings.permissions.filter((c) => c !== permission)
                        )
                      }
                      value={1}
                      checked={requestSettings.permissions.includes(permission)}
                    />
                    <label htmlFor={`permission-${permission}`}>{permission}</label>
                  </div>
                ))}
              </div>
            </Tab>

            <Tab eventKey={'debug'} title={'Debug'} className="p-3">
              <div className="mb-3">
                <div className="fw-bold">Access token</div>
                <div>
                  <input
                    type="text"
                    onChange={(e) =>
                      e.target.value ? updateRequest('accessToken', e.target.value, true) : updateRequest('accessToken', undefined)
                    }
                    value={requestSettings.accessToken ?? ''}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        }
        contentHeader={
          <div className="p-3 pb-0">
            <CopyToClipboardButton text={restUrl} name="url" />
          </div>
        }
      >
        <div className="position-relative p-3">
          <code className="p-2 d-block">
            <pre>{response ? prettyResponse : 'Loading...'}</pre>
          </code>
          {response ? (
            <div className="position-absolute" style={{ top: '5px', right: '5px' }}>
              <CopyToClipboardButton text={prettyResponse} name="response" buttonOnly />
            </div>
          ) : null}
        </div>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}
