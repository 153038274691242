import {
  ClientContractEntity,
  ClientCustomerEntity,
  ClientProjectEntity,
  ClientSiteDraft,
  ClientRemoteServiceEntity,
} from '@edgebox/api-rest-client';
import { BooleanProperty, EnumProperty, ReferenceProperty } from '@edgebox/data-definition-kit';
import { CopyToClipboardButton, FormRow, formWithValidation } from '@edgebox/react-components';
import {
  EnumTranslation,
  Product,
  SiteApplicationType,
  SiteEnvironmentType,
  SyncCoreDataDefinitionsEnumTranslator,
} from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { ContentBox } from '../../Shared/ContentBox';
import RegisterNewSiteImage from '../../../images/undraw_join_of2w.svg';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import {
  ApiComponent,
  ContractQuickSelect,
  EnumAsRadios,
  IApiComponentState,
  ProjectSelector,
  SyncCoreSelector,
  ViewContractName,
  WithMostRecentContractRevision,
} from '../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { IAppContextProp, withAppContext } from '../../../common/contexts/AppContext';

interface IProps extends JSX.IntrinsicAttributes, IAppContextProp {
  embedded?: boolean;
}

interface IRegistrationSettings {
  customer: ClientCustomerEntity;
  contract?: ClientContractEntity;
  environmentType: SiteEnvironmentType;
  project?: ClientProjectEntity;
  syncCore?: ClientRemoteServiceEntity;
  useProxy?: boolean;
}
class RegistrationSettings implements IRegistrationSettings {
  constructor(values: IRegistrationSettings) {
    Object.assign(this, values);
  }
  @ReferenceProperty(true, ClientCustomerEntity)
  customer!: ClientCustomerEntity;
  @ReferenceProperty(false, ClientContractEntity)
  contract?: ClientContractEntity;
  @EnumProperty(true, SiteEnvironmentType, 'SiteEnvironmentType')
  environmentType!: SiteEnvironmentType;
  @ReferenceProperty(false, ClientProjectEntity)
  project?: ClientProjectEntity;
  @ReferenceProperty(false, ClientRemoteServiceEntity)
  syncCore?: ClientRemoteServiceEntity;
  @BooleanProperty(false)
  useProxy?: boolean;
}

interface IState extends IApiComponentState {
  values?: IRegistrationSettings;
  tokenRefreshTime?: number;
  token?: string;
}

const RegistrationForm = formWithValidation(
  (values) => plainToInstance(RegistrationSettings, values),
  (instance) => instanceToPlain(instance)
);

class RegisterMultipleSitesComponent extends ApiComponent<IProps, IState> {
  async load() {
    const customer = this.props.appContext.customer || (await this.getCurrentCustomer(true));
    const values = new RegistrationSettings({
      customer: customer,
      contract: this.props.appContext.contract,
      project: this.props.appContext.project,
      environmentType: this.props.appContext.project?.type || SiteEnvironmentType.Production,
      useProxy: false,
      syncCore: this.props.appContext.project?.syncCore?.getId() ? await this.props.appContext.project.syncCore.get() : undefined,
    });

    setTimeout(() => this.refreshToken(), 1);

    return {
      values,
    };
  }

  async refreshToken() {
    const tokenRefreshTime = Date.now();
    this.setState({ token: undefined, tokenRefreshTime });
    const { customer, contract, environmentType, project, syncCore } = this.state.values!;
    if (!contract || !project || !syncCore) {
      return;
    }

    const token = await this.api.billing.sites.registerMultipleToken({
      customer,
      contract,
      environmentType,
      project,
      syncCore,
    });
    if (this.state.tokenRefreshTime !== tokenRefreshTime) {
      return;
    }

    this.setState({ token });
  }

  render() {
    const { values, token } = this.state;
    if (!values) {
      return this.renderRequest();
    }

    const { embedded } = this.props;

    const { customer, contract, environmentType, project, syncCore } = values;

    let warning: React.ReactNode = undefined;
    let registrationCode: React.ReactNode = undefined;
    if (!contract) {
      warning = 'Please select a subscription to continue.';
    } else if (!project) {
      warning = 'Please select a project to continue.';
    } else if (!syncCore) {
      warning = 'Please select a Sync Core to continue.';
    } else {
      if (token) {
        const registrationCommand = `drush csr ${environmentType} ${contract?.uuid || '<contract>'} ${
          project?.uuid || '<project>'
        } ${token}`;
        registrationCode = (
          <div>
            The generated token is valid for 1 hour. If you need another one, please{' '}
            <Button variant="link" onClick={() => this.refreshToken()}>
              refresh
            </Button>
            .<br />
            <CopyToClipboardButton name="command" text={registrationCommand} />
          </div>
        );
      } else {
        registrationCode = <div>Generating token...</div>;
      }
    }

    const form = (
      <RegistrationForm initialValues={values} onSubmit={async () => {}} labels="above">
        {({ values: formValues, setValue, errors }) => {
          return (
            <>
              {!embedded && (
                <FormRow name="contract" label="Subscription" required>
                  {!contract || this.props.appContext.contract?.id !== contract?.id ? (
                    <ContractQuickSelect
                      name={'contract.id'}
                      forCustomer={customer.id}
                      onChange={(selectedContract) => {
                        if (contract && selectedContract?.id !== contract.id) {
                          values.project = undefined;

                          if (syncCore) {
                            values.syncCore = undefined;
                          }
                        }

                        values.contract = selectedContract;
                        this.setState({ values });

                        this.refreshToken();
                      }}
                    />
                  ) : (
                    <ViewContractName entity={contract} />
                  )}
                  <div className={'text-warning'}>
                    <strong>The subscription can't be changed later.</strong> Sites that are not in the same subscription can't exchange any
                    content.
                  </div>
                </FormRow>
              )}

              <WithMostRecentContractRevision contractId={contract?.id}>
                {(mostRecent) => {
                  const availableEnvironmentTypes: EnumTranslation<SiteEnvironmentType> = {
                    ...SyncCoreDataDefinitionsEnumTranslator.translateEnum('SiteEnvironmentType'),
                  };
                  if (mostRecent.current?.product !== Product.Staging) {
                    delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Staging];
                  }

                  if (project && this.props.appContext.project?.id === project?.id) {
                    if (project.type === SiteEnvironmentType.Production) {
                      delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Local];
                      delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Testing];
                    } else {
                      delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Local];
                      delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Staging];
                      delete (availableEnvironmentTypes as any)[SiteEnvironmentType.Production];
                    }
                  }

                  if (embedded && Object.keys(availableEnvironmentTypes).length < 2) {
                    return null;
                  }

                  return (
                    <>
                      <EnumAsRadios<ClientSiteDraft, SiteEnvironmentType>
                        withoutEntity
                        enumValues={availableEnvironmentTypes}
                        currentValue={values.environmentType}
                        name={'environmentType'}
                        label="Environment"
                        setValue={(name, environmentType) => {
                          if (environmentType) {
                            values.environmentType = environmentType;
                          }
                          if (
                            project &&
                            (environmentType === SiteEnvironmentType.Testing || environmentType === SiteEnvironmentType.Local
                              ? project.type !== SiteEnvironmentType.Testing
                              : project.type === SiteEnvironmentType.Testing)
                          ) {
                            values.project = undefined;
                          }
                          this.setState({ values });

                          this.refreshToken();
                        }}
                      />
                      {(values.environmentType === SiteEnvironmentType.Testing || values.environmentType === SiteEnvironmentType.Local) &&
                        !embedded && (
                          <div className={'text-warning'}>
                            <strong>The environment type can't be changed later.</strong> Sites that are of type <em>Testing</em> or{' '}
                            <em>Local</em> can only exchange content with other sites of type <em>Testing</em> and <em>Local</em>.
                            <br />
                            Sites of type <em>Testing</em> and <em>Local</em> don't count into your usage limit, so you can connect as many
                            sites for testing as you like.
                            <br />
                            Sites of type <em>Testing</em> and <em>Local</em> are not covered by any SLA and are only supported on a
                            best-effort basis.
                            <br />
                            Incorrectly assigning <em>Testing</em> or <em>Local</em> to sites you are using in production can result in an
                            immediate deactivation of your account and cancellation of all services.
                          </div>
                        )}
                    </>
                  );
                }}
              </WithMostRecentContractRevision>

              {!embedded && (
                <FormRow name="project" label="Project">
                  <ProjectSelector
                    disabled={project && project?.id === this.props.appContext.project?.id}
                    forCustomer={values.customer.id}
                    contract={contract}
                    name={'project.id'}
                    value={project}
                    forType={
                      values.environmentType === SiteEnvironmentType.Testing || values.environmentType === SiteEnvironmentType.Local
                        ? SiteEnvironmentType.Testing
                        : SiteEnvironmentType.Production
                    }
                    onSelected={(selectedProject) => {
                      values.project = selectedProject;
                      this.setState({ values });

                      this.refreshToken();
                    }}
                    noAdd
                  />
                  <div className={'text-warning'}>
                    <strong>The project can't be changed later.</strong> Sites that are not in the same project can't exchange any content.
                  </div>
                </FormRow>
              )}

              {!embedded && (
                <FormRow name="syncCore" label="Sync Core">
                  {!project?.syncCore?.getId() ? (
                    <SyncCoreSelector
                      appType={SiteApplicationType.SyncCore}
                      forCustomer={customer.id}
                      forContract={contract?.id}
                      autoSelect
                      name={'syncCore.id'}
                      value={syncCore}
                      onSelected={(selectedSyncCore) => {
                        values.syncCore = selectedSyncCore;
                        this.setState({ values });

                        this.refreshToken();
                      }}
                    />
                  ) : (
                    syncCore?.name
                  )}
                </FormRow>
              )}
            </>
          );
        }}
      </RegistrationForm>
    );

    if (embedded) {
      return (
        <>
          {form}
          {warning && <div className="text-warning">{warning}</div>}
          {registrationCode}
        </>
      );
    }

    return (
      <>
        <HeadlineWithBreadcrumbNavigation>Register multiple sites</HeadlineWithBreadcrumbNavigation>

        <ContentBox>
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={RegisterNewSiteImage} width={'100%'} />
            </Col>
            <Col className={'pe-5 pb-5'}>{form}</Col>
          </Row>
          <div>
            <h2>Drush command</h2>
            {warning && <div className="text-warning">{warning}</div>}
            {registrationCode}
          </div>
        </ContentBox>
      </>
    );
  }
}

export const RegisterMultipleSites = withAppContext(RegisterMultipleSitesComponent);
