import { ClientRemoteServiceEntity } from '@edgebox/api-rest-client';
import { InternalId, IPagedListResponse } from '@edgebox/data-definition-kit';
import { RemoteServiceHostingType } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState } from '../../Form/EntitySelector';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { ITextSearchFilter } from '../../PagedList';
import { RemoteServiceHostingTypeBadge } from '../Badges/RemoteServiceHostingType';
import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';

interface IProps extends IEntitySelectorProps<ClientRemoteServiceEntity> {
  appType: SiteApplicationType.ContentCloud | SiteApplicationType.SyncCore;
  forCustomer?: InternalId;
  forContract?: InternalId;
  autoSelect?: boolean;
  excludePublic?: boolean;
}

interface IState extends IEntitySelectorState<ClientRemoteServiceEntity> {}

export class SyncCoreSelector extends EntitySelector<ClientRemoteServiceEntity, IProps, IState> {
  renderEmpty() {
    return 'Select Sync Core';
  }

  async load() {
    const result = await super.load();

    if (this.props.autoSelect) {
      await this.selectDefault();
    }

    return result;
  }

  async selectDefault() {
    const before = this.state.selected;
    const startUpdated = this.state.updateResults;
    const results = await this.search(0);
    // Properties changed in the meantime; need to skip.
    if (startUpdated !== this.state.updateResults) {
      return;
    }

    if ((before?.id || null) !== (this.state.selected?.id || null)) {
      return;
    }

    if (!results.totalNumberOfItems) {
      this.setState({
        selected: this.props.value,
      });
      return;
    }

    // If the value given from the parent exists, we prefer that.
    let value = this.props.value;
    if (value && (value = results.items.find((c) => c.id === value?.id))) {
      this.select(value);
    }

    // If the previously selected Sync Core is still included in the results, prefer that.
    value = this.state.selected;
    if (value && (value = results.items.find((c) => c.id === value?.id))) {
      this.select(value);
    }

    // Prefer on-premise if one is available.
    const onPremise = results.items.find((c) => c.type === RemoteServiceHostingType.OnPremise);
    if (onPremise) {
      this.select(onPremise);
      return;
    }

    const customer = await this.api.currentUser!.customer!.get();
    const regionMatch = results.items.find((c) => c.regions?.includes(customer!.region));
    // Prefer the cloud Sync Core that fits the region best.
    if (regionMatch) {
      this.select(regionMatch);
      return;
    }

    // Fallback in case we are missing a region.
    this.select(results.items[0]);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    super.componentDidUpdate(prevProps, prevState, snapshot);

    // Must update the list if these change.
    if (this.props.forCustomer !== prevProps.forCustomer || this.props.forContract !== prevProps.forContract) {
      this.setState({
        updateResults: Date.now(),
      });

      if (this.props.autoSelect) {
        this.selectDefault();
      }
    }
  }

  renderCurrentValue(selected: ClientRemoteServiceEntity): React.ReactElement | string {
    return selected.name;
  }

  search(page: number, query?: ITextSearchFilter): Promise<IPagedListResponse<ClientRemoteServiceEntity>> {
    const { forCustomer, forContract } = this.props;

    return this.api.syndication.remoteServices.search(
      this.props.appType,
      undefined,
      { page },
      {
        customerId: forCustomer,
        contractId: forContract,
        includePublic: this.props.excludePublic ? false : true,
      }
    );
  }

  renderAddForm(): React.ReactElement | null {
    return null;
  }

  renderItem(entity: ClientRemoteServiceEntity): React.ReactElement {
    if (this.state.variant === 'radios') {
      return <>{entity.name}</>;
    }
    return (
      <Row>
        <Col>
          <h3>
            {entity.name}
            <RemoteServiceHostingTypeBadge type={entity.type} />
          </h3>
          {entity.type === RemoteServiceHostingType.OnPremise && (
            <div className={'text-muted'}>
              Created: <FormatDateTime date={entity.createdAt} />
            </div>
          )}
        </Col>
      </Row>
    );
  }

  renderItemAsText(entity: ClientRemoteServiceEntity): string {
    return entity.name;
  }
}
