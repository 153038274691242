import React from 'react';

type Props = {
  children?: React.ReactNode;
};

export function ContentCloudPageLayout({ children }: Props) {
  return (
    <div className={`mx-auto shadow bg-white rounded`} style={{ maxWidth: '1600px', maxHeight: '100%' }}>
      {children}
    </div>
  );
}
