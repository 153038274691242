import { RemoteServiceStatus } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization';

interface IProps {
  status: RemoteServiceStatus;
}

export class RemoteServiceStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status } = this.props;

    const classes = 'opacity-3';

    const variants = {
      [RemoteServiceStatus.Active]: 'success',
      [RemoteServiceStatus.Retired]: 'dark',
    };

    return (
      <Badge bg={variants[status]} className={classes}>
        <SyncCoreDataDefinitionsEnumValue enumName={'RemoteServiceStatus'} keyName={status} />
      </Badge>
    );
  }
}
