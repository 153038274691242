import React, { useEffect, useMemo, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentData, withContentCloud } from '../WithContentCloud';
import { ContentEntry, ContentTypeEntry, LocaleEntry, RestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, convertListResponse, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { Badge, Col, Row } from 'react-bootstrap';
import { getStyleColors, PagedList } from '../../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { AsyncPaginate } from 'react-select-async-paginate';
import { HeaderCol } from '@edgebox/react-components';
import moment from 'moment';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons/faBarsSort';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentCloudListPageLayout } from '../Layouts/ContentCloudListPageLayout';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';

const ENTRIES_PER_PAGE = 10;
const FILTER_ENTRIES_PER_PAGE = 10;

interface Props extends ContentCloudComponentData {
  byStatus?: 'published' | 'draft' | null;
  byContentTypeId?: string | null;
}

function ContentCollectionPage({ environment, space, accessToken, contentCloud, byContentTypeId, byStatus }: Props) {
  const client = useMemo(
    () =>
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );
  const previewClient = useMemo(
    () =>
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'preview',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [localeEntry, setLocaleEntry] = useState<LocaleEntry | null>(null);

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(() => !!loadAllContentTypes(client).then((contentTypes) => setContentTypes(contentTypes)) && void 0, [client]);

  const { primary, danger } = getStyleColors();

  const displayContentTypes = contentTypes?.filter((c) => c.isIndependent);

  const displayComponentTypes = contentTypes?.filter((c) => !c.isIndependent && !c.isInline);

  return (
    <ContentCloudPageLayout>
      <ContentCloudListPageLayout
        filterHeader={
          <>
            <div className={`px-2 pb-3 border-bottom`}>
              <Link to={`?`} className="text-decoration-none text-black">
                <div className={`${!byContentTypeId && !byStatus ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faBarsSort} className="me-2" />
                  All content
                </div>
              </Link>
            </div>
            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Status</div>
              <Link to={`?status=published`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'published' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faGlobe} className="me-2" />
                  Published
                </div>
              </Link>
              <Link to={`?status=draft`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'draft' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Draft
                </div>
              </Link>
            </div>
          </>
        }
        filterBody={
          <>
            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Content Types</div>
              {displayContentTypes?.map((contentType) => (
                <Link key={contentType.id} to={`?contentTypeId=${contentType.id}`} className="text-decoration-none text-black">
                  <div className={`${byContentTypeId === contentType.id ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                    {contentType.sys.name}
                  </div>
                </Link>
              )) ?? '...'}
            </div>
            <div className={`px-2 py-3`}>
              <div className="text-muted small pe-2">Component Types</div>
              {displayComponentTypes?.map((contentType) => (
                <Link key={contentType.id} to={`?contentTypeId=${contentType.id}`} className="text-decoration-none text-black">
                  <div className={`${byContentTypeId === contentType.id ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                    {contentType.sys.name}
                  </div>
                </Link>
              )) ?? '...'}
            </div>
          </>
        }
        contentHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2">
            {byStatus === 'draft' ? 'Draft' : byStatus === 'published' ? 'Published' : 'All'}{' '}
            {byContentTypeId ? (contentTypes?.find((c) => c.id === byContentTypeId)?.name ?? 'Content') : 'Content'}
          </HeadlineWithBreadcrumbNavigation>
        }
      >
        <PagedList<ContentEntry, { locale?: string; contentTypeId?: string | null; status?: string | null }>
          searchable
          filter={{
            contentTypeId: byContentTypeId,
            status: byStatus,
          }}
          renderListHeader={() => (
            <Row>
              <HeaderCol xs={4}>Name</HeaderCol>
              <HeaderCol xs={3}>Content Type</HeaderCol>
              <HeaderCol xs={2}>Updated</HeaderCol>
              <HeaderCol xs={2}>Status</HeaderCol>
              <HeaderCol xs={1}>Actions</HeaderCol>
            </Row>
          )}
          renderFilters={(onChange) => (
            <>
              <Col xs={9}></Col>
              <Col xs={3}>
                <AsyncPaginate
                  isClearable={true}
                  isMulti={false}
                  loadOptions={async (search, lastOptions, additional: any) => {
                    const page = additional ? additional.page + 1 : 0;
                    const response = await client.localeCollection({
                      skip: page * FILTER_ENTRIES_PER_PAGE,
                      limit: FILTER_ENTRIES_PER_PAGE,
                      'sys.name': search,
                    });

                    return {
                      options: response.items,
                      hasMore: response.skip < response.total,
                      additional: { page },
                    };
                  }}
                  placeholder="Change Locale..."
                  value={localeEntry}
                  getOptionValue={(option) => option.sys.id}
                  getOptionLabel={(option) => option.sys.name!}
                  onChange={(option) => {
                    setLocaleEntry(option);
                    onChange('locale', option?.code);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                />
              </Col>
            </>
          )}
          renderItem={(contentEntry) => (
            <Link
              to={`/content-cloud/content/${contentEntry.sys.id}?locale=${localeEntry?.code ?? ''}`}
              className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
              key={contentEntry.sys.id}
            >
              <Row className="m-0 p-0">
                <Col xs={4} className="ms-0 me-0 pt-2">
                  {contentEntry.sys.name}
                </Col>
                <Col xs={3} className="ms-0 me-0 pt-2">
                  {contentEntry.sys.contentType?.sys.type === 'ContentType' ? (
                    contentEntry.sys.contentType?.sys.name
                  ) : (
                    <Badge bg="neutral">contentEntry.sys.contentType?.sys.id</Badge>
                  )}
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  <EntryRelativeDate date={contentEntry.sys.versionCreatedAt} />
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  <EntryPublishedBadge sys={contentEntry.sys} />
                </Col>
                <Col xs={1} className="text-end fs-4 text-light pt-1">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Row>
            </Link>
          )}
          request={async (page, filter) => {
            const response = await convertListResponse(
              (filter?.status === 'published' ? client : previewClient).contentCollection({
                limit: ENTRIES_PER_PAGE,
                skip: page * ENTRIES_PER_PAGE,
                ...(filter
                  ? {
                      locale: filter.locale,
                    }
                  : {}),
                include: 2,
                ...(filter?.contentTypeId
                  ? {
                      content_type: filter.contentTypeId,
                    }
                  : {}),
                order: '-sys.versionCreatedAt',
                ...(filter?.status === 'draft'
                  ? {
                      'sys.localizationFirstPublishedAt[exists]': 'false',
                    }
                  : {}),
              }),
              ENTRIES_PER_PAGE
            );

            let typeIds = response.items.filter((c) => c.sys.contentType?.sys.type === 'Link').map((c) => c.sys.contentType?.sys.id);
            typeIds = typeIds.filter((c, i) => typeIds.indexOf(c) === i);
            const contentTypes = await Promise.all(typeIds.map((c) => client.contentTypeEntry({ id: c })));
            for (const item of response.items) {
              if (item.sys.contentType?.sys.type !== 'Link') {
                continue;
              }
              const contentTypeId = item.sys.contentType.sys.id;

              const contentType = contentTypes.find((c) => c?.sys.id === contentTypeId);
              if (contentType) {
                item.sys.contentType = contentType;
              }
            }

            return response;
          }}
        />
      </ContentCloudListPageLayout>
    </ContentCloudPageLayout>
  );
}

export default withContentCloud(ContentCollectionPage, [
  Permission.CONTENT_READ,
  Permission.SPACE_READ,
  Permission.CONTENT_TYPE_READ,
  Permission.PREVIEW,
]);
