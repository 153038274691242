import { IPagedListResponse } from '@edgebox/data-definition-kit';
import { Pager } from '@edgebox/react-components';
import React from 'react';
import { Button, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/cjs/Alert';
import Container from 'react-bootstrap/cjs/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ApiComponent, IApiComponentState } from '../services/ApiComponent';

export type OnChangeCallback<Filter> = (name: keyof Filter, value: any, searchImmediately?: boolean) => void;

export type RenderFiltersCallback<Filter> = (onChange: OnChangeCallback<Filter>) => React.ReactNode;

export interface ITextSearchFilter {
  search?: string;
}

interface IProps<Type, Filter = ITextSearchFilter> {
  searchable?: boolean;
  renderFilters?: RenderFiltersCallback<Filter>;
  renderListHeader?: () => React.ReactNode;
  renderItem: (item: Type, index: number) => React.ReactNode;
  renderList?: (items: React.ReactNode) => React.ReactNode;
  request: (page: number, filter?: Filter) => Promise<IPagedListResponse<Type>>;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  notEmptyheader?: React.ReactNode;
  numberOfResultsLabel?: React.ReactNode;
  infinite?: boolean;
  staticItems?: Type[];
  hidePagerIfNotNeeded?: boolean;
  filter?: Filter;
}

interface IState<Type, Filter extends object = ITextSearchFilter> extends IApiComponentState {
  filter: Filter;
  page?: number;
  result?: IPagedListResponse<Type>;
}

export class PagedList<Type, Filter extends object = ITextSearchFilter> extends ApiComponent<IProps<Type, Filter>, IState<Type, Filter>> {
  constructor(props: IProps<Type, Filter>) {
    super(props, {
      filter: props.filter ?? ({} as Filter),
    });
  }

  initialLoad = true;
  startSearch: any = undefined;

  componentDidUpdate(prevProps: Readonly<IProps<Type, Filter>>, prevState: Readonly<IState<Type, Filter>>, snapshot?: any): void {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        result: undefined,
      });

      this.load(this.props.filter);
    }
  }

  async load(filter?: Partial<Filter>, page?: number): Promise<Partial<IState<Type, Filter>>> {
    const { request, infinite } = this.props;

    const previousFilterId = JSON.stringify(this.state.filter);
    const newFilter = filter ? { ...this.state.filter, ...filter } : this.state.filter;
    const newFilterId = JSON.stringify(newFilter);

    if (page === undefined) {
      // Prevent unnecessary searches
      if ((!filter || previousFilterId === newFilterId) && !this.initialLoad) {
        return {};
      }

      this.initialLoad = false;

      // New query => always start at first page.
      page = 0;
    }

    const previousResult = this.state.result;

    this.setState({
      filter: newFilter,
      page,
      result: infinite ? previousResult : undefined,
    });

    const result = await request(page, newFilter);

    const filterIdAfterRequest = JSON.stringify(this.state.filter);

    // User changed search while this request was running. Skip.
    if (filterIdAfterRequest !== newFilterId || this.state.page !== page) {
      return {};
    }

    // Combine results for infinite pagination.
    if (infinite && previousResult && previousResult.items.length > 0) {
      result.items = previousResult.items.concat(result.items);
      result.numberOfPages = result.numberOfPages - result.page;
      result.page = 0;
      result.itemsPerPage = result.items.length;
    }

    return {
      result,
    };
  }

  render(): React.ReactElement {
    const {
      searchable,
      emptyMessage,
      renderItem,
      renderList,
      renderListHeader,
      numberOfResultsLabel,
      renderFilters,
      emptyMessageWithNoFilters,
      infinite,
      staticItems,
      notEmptyheader,
      hidePagerIfNotNeeded,
    } = this.props;
    const { result, filter, page } = this.state;

    let search: React.ReactNode;
    if (searchable) {
      const setFilter: OnChangeCallback<Filter> = (name: keyof Filter, value: any, setImmediately?: boolean) => {
        this.setState({
          result: undefined,
        });

        if (this.startSearch) {
          clearTimeout(this.startSearch);
        }

        const newFilterValue: Partial<Filter> = {
          [name]: value,
        } as Partial<Filter>;

        if (setImmediately) {
          this.load(newFilterValue);
        } else {
          this.startSearch = setTimeout(() => this.load(newFilterValue), 300);
        }
      };
      const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement | any>
      ) => {
        const value = (e.target as HTMLInputElement).value;
        setFilter('search' as keyof Filter, value);
      };

      const filters = renderFilters ? (
        renderFilters(setFilter)
      ) : (
        <Row>
          <Col>
            <Form.Control placeholder={'Enter search term...'} onKeyPress={onChange} onChange={onChange} />
          </Col>
        </Row>
      );

      search = (
        <>
          <Form className={'mb-3'}>{filters}</Form>
        </>
      );
    } else {
      search = undefined;
    }

    let items: React.ReactNode;
    if (!result) {
      items = this.renderRequest();
    } else {
      if (!result.items.length) {
        items = emptyMessage ? (
          emptyMessage
        ) : (
          <Alert className={'no-items'} variant={'light'}>
            {searchable ? 'No matches.' : 'No items.'}
          </Alert>
        );
      } else {
        const itemList = [
          ...result.items,
          ...(staticItems?.filter((a) => !result.items.find((b) => (a as any).id === (b as any).id)) || []),
        ];
        items = itemList.map(renderItem);

        items = (
          <>
            {renderListHeader && renderListHeader()}

            <div className={'items'}>{renderList ? renderList(items) : items}</div>
          </>
        );
      }
    }

    let pager: React.ReactNode;
    if (result?.totalNumberOfItems) {
      if (infinite) {
        if (result.numberOfPages > 1) {
          pager = (
            <Button variant="link" onClick={() => this.load(undefined, (page || 0) + 1)}>
              load more
            </Button>
          );
        } else {
          pager = undefined;
        }
      } else if (!hidePagerIfNotNeeded || result.numberOfPages > 1) {
        pager = (
          <Pager
            numberOfResultsLabel={numberOfResultsLabel}
            className={'mt-4'}
            searchable={searchable}
            page={result.page}
            numberOfPages={result.numberOfPages}
            totalNumberOfItems={result.totalNumberOfItems}
            onChange={(page: number) => this.load(undefined, page)}
          />
        );
      }
    } else {
      pager = undefined;
    }

    const hasAnyFilters = !!Object.values(filter).find((c) => !!c);

    return (
      <Container>
        {!hasAnyFilters && result && !result.items.length && emptyMessageWithNoFilters ? (
          emptyMessageWithNoFilters
        ) : (
          <>
            {notEmptyheader}

            {search}

            {items}

            {pager}
          </>
        )}
      </Container>
    );
  }
}
