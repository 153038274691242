import { RemoteServiceHostingType } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';

interface IProps {
  type: RemoteServiceHostingType;
}

export class RemoteServiceHostingTypeBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { type } = this.props;

    const classes = 'opacity-3';

    const variants = {
      [RemoteServiceHostingType.SaaS]: 'primary',
      [RemoteServiceHostingType.OnPremise]: 'primary',
    };

    return (
      <Badge bg={variants[type]} className={classes}>
        <SyncCoreDataDefinitionsEnumValue enumName={'RemoteServiceHostingType'} keyName={type} />
      </Badge>
    );
  }
}
