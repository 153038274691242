import { Uuid } from '@edgebox/data-definition-kit';

export interface SystemOrganization {
  uuid: string;
  id: string;
  name: string;
}
export interface OrganizationQueryVariables {
  organizationUuid: string;
}
const ORGANIZATION_QUERY = `
query getOrganization($organizationUuid: String!) {
  result: organization(uuid: $organizationUuid) {
    id
    uuid
    name
  }
}
`;

export interface SystemEnvironment {
  id: string;
  customId: string;
  domainKey: string;
  name: string;
}
export interface SystemLocale {
  id: string;
  code: string;
  name: string;
  fallbackCode?: string;
}
export interface SystemSpace {
  id: string;
  uuid: string;
  domainKey: string;
  name: string;

  defaultEnvironment: SystemEnvironment;
  environments: SystemEnvironment[];

  defaultLocale: SystemLocale;
  locales: SystemLocale[];
}
export interface ISpaceQueryVariables {
  organizationId: string;
  spaceUuid: string;
}
const SPACE_QUERY = `
query getSpace($organizationId: String!, $spaceUuid: String!) {
  result: space(organizationId: $organizationId, uuid: $spaceUuid) {
    id
    uuid
    domainKey
    name

    defaultEnvironment {
      id
      customId
      name
      domainKey
    }

    environments {
      id
      customId
      name
      domainKey
    }

    defaultLocale {
      id
      code
      name
      fallbackCode
    }

    locales {
      id
      code
      name
      fallbackCode
    }
  }
}
`;

export class PublisherClient {
  constructor(
    private readonly options: {
      baseUrl: string;
      accessToken: string;
      version: string | 'latest';
    }
  ) {}

  query<ResponseType extends {}, Variables extends Record<string, any>, Name extends string = 'result'>(
    {
      query,
      variables,
    }: {
      query: string;
      variables: Variables;
    },
    returnKey: Name
  ): Promise<ResponseType>;
  query<ResponseType extends {}, Variables extends Record<string, any>, Name extends string = 'result'>({
    query,
    variables,
  }: {
    query: string;
    variables: Variables;
  }): Promise<{ [key in Name]: ResponseType }>;
  async query<ResponseType extends {}, Variables extends Record<string, any>, Name extends string = 'result'>(
    {
      query,
      variables,
    }: {
      query: string;
      variables: Variables;
    },
    returnKey?: Name
  ): Promise<any> {
    const response = await fetch(`${this.options.baseUrl}/${this.options.version}/graphql`, {
      headers: {
        Authorization: `Bearer ${this.options.accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseBody = await response.json();

    responseBody.errors && console.log(responseBody.errors);

    return returnKey ? responseBody.data?.[returnKey] : responseBody.data;
  }

  async getOrganization(organizationUuid: Uuid): Promise<SystemOrganization> {
    return this.query<SystemOrganization, OrganizationQueryVariables>(
      {
        query: ORGANIZATION_QUERY,
        variables: {
          organizationUuid,
        },
      },
      'result'
    );
  }

  async getSpace(organizationId: string, spaceUuid: Uuid): Promise<SystemSpace> {
    return this.query<SystemSpace, ISpaceQueryVariables>(
      {
        query: SPACE_QUERY,
        variables: {
          organizationId,
          spaceUuid,
        },
      },
      'result'
    );
  }
}
