import React, { useEffect, useMemo, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentData, withContentCloud } from '../WithContentCloud';
import { ContentTypeEntry, RestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { Badge, Col, ProgressBar, Row } from 'react-bootstrap';
import { PagedList } from '../../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faPuzzlePiece } from '@fortawesome/pro-light-svg-icons/faPuzzlePiece';
import { faInputText } from '@fortawesome/pro-light-svg-icons/faInputText';
import { HeaderCol } from '@edgebox/react-components';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentCloudListPageLayout } from '../Layouts/ContentCloudListPageLayout';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons/faBarsSort';
import { SanitizeHtml } from '../SanitizeHtml';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';

interface Props extends ContentCloudComponentData {
  //byStatus?: 'published' | 'draft' | null;
  byType?: 'independent' | 'component' | 'property' | null;
}

function ContentTypeCollectionPage({ environment, space, accessToken, contentCloud, byType }: Props) {
  const client = useMemo(
    () =>
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () =>
      !!loadAllContentTypes(client).then((contentTypes) =>
        setContentTypes(contentTypes.sort((a, b) => a.name.localeCompare(b.name)).filter((c) => !c.machineName.endsWith('UserData')))
      ) && void 0,
    [client]
  );

  const filteredTypes = useMemo(
    () => ({
      independent: contentTypes?.filter((contentType) => contentType.isIndependent),
      component: contentTypes?.filter((contentType) => !contentType.isIndependent && !contentType.isInline),
      property: contentTypes?.filter((contentType) => contentType.isInline),
    }),
    [contentTypes]
  );

  if (!contentTypes) {
    return (
      <ContentCloudPageLayout>
        <ProgressBar />
      </ContentCloudPageLayout>
    );
  }

  return (
    <ContentCloudPageLayout>
      <ContentCloudListPageLayout
        filterHeader={
          <>
            <div className={`px-2 pb-3 border-bottom`}>
              <Link to={`?`} className="text-decoration-none text-black">
                <div className={`${!byType ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faBarsSort} className="me-2" />
                  All content types
                </div>
              </Link>
            </div>

            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Type</div>
              <Link to={`?type=independent`} className="text-decoration-none text-black">
                <div className={`${byType === 'independent' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faFile} className="me-2" />
                  Independent
                  <Badge bg="light" className="ms-2">
                    {filteredTypes.independent?.length}
                  </Badge>
                </div>
              </Link>
              <Link to={`?type=component`} className="text-decoration-none text-black">
                <div className={`${byType === 'component' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faPuzzlePiece} className="me-2" />
                  Components
                  <Badge bg="light" className="ms-2">
                    {filteredTypes.component?.length}
                  </Badge>
                </div>
              </Link>
              <Link to={`?type=property`} className="text-decoration-none text-black">
                <div className={`${byType === 'property' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faInputText} className="me-2" />
                  Properties
                  <Badge bg="light" className="ms-2">
                    {filteredTypes.property?.length}
                  </Badge>
                </div>
              </Link>
            </div>
          </>
        }
        contentHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2">
            {byType === 'independent'
              ? 'Independent content types'
              : byType === 'component'
                ? 'Component types'
                : byType === 'property'
                  ? 'Property types'
                  : 'Content types'}
          </HeadlineWithBreadcrumbNavigation>
        }
      >
        <PagedList<ContentTypeEntry, { type?: string | null; search?: string }>
          searchable
          filter={{
            type: byType,
          }}
          renderListHeader={() => (
            <Row>
              <HeaderCol xs={6}>Name</HeaderCol>
              <HeaderCol xs={2}>Fields</HeaderCol>
              <HeaderCol xs={3}>Updated</HeaderCol>
              <HeaderCol xs={1}>Actions</HeaderCol>
            </Row>
          )}
          renderItem={(contentTypeEntry) => (
            <Link
              to={`/content-cloud/content-model/${contentTypeEntry.sys.id}`}
              className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
              key={contentTypeEntry.sys.id}
            >
              <Row className="m-0 p-0">
                <Col xs={6} className="ms-0 me-0 pt-2">
                  {contentTypeEntry.sys.name}
                  <div className="small text-muted">
                    {contentTypeEntry.sys.description && <SanitizeHtml>{contentTypeEntry.sys.description}</SanitizeHtml>}
                  </div>
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  {contentTypeEntry.properties.length}
                </Col>
                <Col xs={3} className="ms-0 me-0 pt-2">
                  <EntryRelativeDate date={contentTypeEntry.sys.versionCreatedAt} />
                </Col>
                <Col xs={1} className="text-end fs-4 text-light pt-1">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Row>
            </Link>
          )}
          request={async (page, filter) => {
            let items = filteredTypes[(filter?.type ?? '') as keyof typeof filteredTypes] ?? contentTypes;

            if (filter?.search) {
              const search = filter.search.toLowerCase();
              items = items.filter((c) => c.name.toLowerCase().includes(search));
            }

            return {
              items,
              itemsPerPage: items.length,
              numberOfPages: 1,
              page: 0,
              totalNumberOfItems: items.length,
            };
          }}
        />
      </ContentCloudListPageLayout>
    </ContentCloudPageLayout>
  );
}

export default withContentCloud(ContentTypeCollectionPage, [Permission.CONTENT_TYPE_READ]);
