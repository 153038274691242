import * as Sentry from '@sentry/react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { BackendAddContract } from './Backend/Billing/BackendAddContract';
import { BackendAddContractRevision } from './Backend/Billing/BackendAddContractRevision';
import { BackendCustomerDetail } from './Backend/Billing/BackendCustomerDetail';
import { BackendCustomerList } from './Backend/Billing/BackendCustomerList';
import { BackendInvoiceList } from './Backend/Billing/BackendInvoiceList';
import { BackendSettings } from './Backend/settings/BackendSettings';
import { BackendLogs } from './Backend/Syndication/BackendLogs';
import { BackendProjectList } from './Backend/Syndication/BackendProjectList';
import { BackendSiteList } from './Backend/Syndication/BackendSiteList';
import { BackendSyncCoreList } from './Backend/Syndication/BackendSyncCoreList';
import { AccountCompany } from './Customer/Account/AccountCompany';
import { AccountBilling } from './Customer/Account/AccountBilling';
import { AccountPayment } from './Customer/Account/AccountPayment';
import { AccountProfile } from './Customer/Account/AccountProfile';
import { AccountUsers } from './Customer/Account/AccountUsers';
import { CheckoutPage } from './Customer/CheckoutPage';
import { ContentDashboard } from './Customer/Syndication/ContentDashboard';
import { ContractDetail } from './Customer/Syndication/ContractDetail';
import { ContractList } from './Customer/Syndication/ContractList';
import { ContractUpgrade } from './Customer/Syndication/ContractUpgrade';
import { ProjectDetail } from './Customer/Syndication/ProjectDetail';
import { ProjectList } from './Customer/Syndication/ProjectList';
import { RegisterMultipleSites } from './Customer/Syndication/RegisterMultipleSites';
import { RegisterSite } from './Customer/Syndication/RegisterSite';
import { SiteDetail } from './Customer/Syndication/SiteDetail';
import { SiteList } from './Customer/Syndication/SiteList';
import { SyncCoreDetail } from './Customer/Syndication/SyncCoreDetail';
import { SyncCoreList } from './Customer/Syndication/SyncCoreList';
import { NotFoundPage } from './NotFoundPage';
import { ILocationProp, WithParams, withLocation } from './RouterHelper';
import { SidebarNavigation } from './SidebarNavigation';
import { Dashboard } from './Customer/Dashboard';
import { ApiComponent, IApiComponentState } from '../common';
import { BackendContracts } from './Backend/Billing/BackendContracts';
import { GetStartedInstructions } from './Customer/GetStartedInstructions';
import { WithSite } from './WithSite';
import { WithAppContext } from '../common/contexts/AppContext';
import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import { ContentCloudIntroduction } from './Customer/ContentCloud/Pages/ContentCloudIntroduction';
import GraphQlExplorer from './Customer/ContentCloud/Pages/GraphQlExplorer';
import ContentTypeCollectionPage from './Customer/ContentCloud/Types/ContentTypeCollectionPage';
import ContentCollectionPage from './Customer/ContentCloud/Content/ContentCollectionPage';
import AssetCollectionPage from './Customer/ContentCloud/Content/AssetCollectionPage';
import TagCollectionPage from './Customer/ContentCloud/Content/TagCollectionPage';
import { ContentEntryPage } from './Customer/ContentCloud/Content/ContentEntryPage';
import RestExplorer from './Customer/ContentCloud/Pages/RestExplorer';
import { ContentTypeEntryPage } from './Customer/ContentCloud/Types/ContentTypeEntryPage';
import { AssetEntryPage } from './Customer/ContentCloud/Content/AssetEntryPage';
import { TagEntryPage } from './Customer/ContentCloud/Content/TagEntryPage';
import { ContentUserDataTypeCollectionPage } from './Customer/ContentCloud/Types/ContentUserDataTypeCollectionPage';

interface IExternalProps {}
interface IProps extends IExternalProps, ILocationProp {}

interface IState extends IApiComponentState {}

export class RoutingContainerClass extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  public render() {
    let sidebar: React.ReactNode = this.props.location.pathname === '/sites/register' ? undefined : <SidebarNavigation />;

    const search = new URLSearchParams(this.props.location.search);

    const isContentCloudContainer = this.props.location.pathname.match(/^\/projects\/[^/]+\/content-cloud/);

    return (
      <div className={'d-flex align-items-stretch flex-columns flex-grow-1'}>
        <div className={'flex-grow-0'}>{sidebar}</div>
        <div className={'flex-grow-1'}>
          <div id="page-content" className={isContentCloudContainer ? '' : 'overflow-auto'}>
            <div
              className={`content ${
                this.props.location.pathname.substring(0, 8) === '/content' ||
                this.props.location.pathname.substring(0, 8) === '/updates' ||
                this.props.location.pathname.match(/^\/projects\/[a-z0-9]+\/(content|updates)/) ||
                this.props.location.pathname.substring(0, 25) === '/backend/syndication/logs'
                  ? 'p-3'
                  : 'container'
              }`}
              style={isContentCloudContainer ? { maxHeight: '100%', maxWidth: '100%' } : {}}
              key={'content'}
            >
              <Sentry.ErrorBoundary>
                <Routes>
                  <Route
                    path=""
                    element={
                      <WithAppContext>
                        {(appContext) =>
                          appContext?.project?.id ? (
                            <Navigate
                              to={`/projects/${appContext.project.id}/${appContext.project.appType === SiteApplicationType.ContentCloud ? 'content-cloud-introduction' : 'dashboard'}${this.props.location.search}`}
                              replace
                            />
                          ) : (
                            <Dashboard />
                          )
                        }
                      </WithAppContext>
                    }
                  />

                  <Route path="account">
                    <Route path="" element={<Navigate to={'/account/profile'} replace />} />

                    <Route path="profile" element={<AccountProfile />} />
                    <Route path="users" element={<AccountUsers />} />
                    <Route path="billing" element={<AccountBilling />} />
                    <Route path="payment" element={<AccountPayment />} />
                    <Route path="company" element={<AccountCompany />} />

                    <Route
                      path="notifications"
                      element={
                        <WithAppContext>
                          {(appContext) => (
                            <Navigate to={`/projects/${appContext?.project?.id}/notifications${this.props.location.search}`} replace />
                          )}
                        </WithAppContext>
                      }
                    />
                  </Route>

                  <Route path="sites/register" element={<RegisterSite />} />
                  <Route path="sites/register-multiple" element={<RegisterMultipleSites />} />

                  <Route path="projects" element={<ProjectList />} />

                  {/* Shared */}
                  <Route
                    path="projects/:project"
                    element={<Navigate to={`${this.props.location.pathname}/details${this.props.location.search}`} />}
                  />
                  <Route path="projects/:project/details" element={<ProjectDetail tab="details" />} />

                  {/* Content Cloud specific */}
                  <Route path="projects/:project/content-cloud" element={<ContentCloudIntroduction />} />

                  <Route
                    path="projects/:project/content-cloud/content-model"
                    element={<ContentTypeCollectionPage byType={search.get('type') as null} />}
                  />
                  <Route
                    path="projects/:project/content-cloud/content-model/:type"
                    element={<Navigate to={`${this.props.location.pathname}/fields${this.props.location.search}`} />}
                  />
                  <Route path="projects/:project/content-cloud/content-model/:type/:tab" element={<ContentTypeEntryPage />} />

                  <Route
                    path="projects/:project/content-cloud/content"
                    element={
                      <ContentCollectionPage byStatus={search.get('status') as null} byContentTypeId={search.get('contentTypeId')} />
                    }
                  />
                  <Route
                    path="projects/:project/content-cloud/content/:content"
                    element={<Navigate to={`${this.props.location.pathname}/content${this.props.location.search}`} />}
                  />
                  <Route path="projects/:project/content-cloud/content/:content/:tab" element={<ContentEntryPage />} />

                  <Route path="projects/:project/content-cloud/content-user-data" element={<ContentUserDataTypeCollectionPage />} />

                  <Route
                    path="projects/:project/content-cloud/assets"
                    element={<AssetCollectionPage byStatus={search.get('status') as null} byMimeTypeGroup={search.get('mimeTypeGroup')} />}
                  />
                  <Route
                    path="projects/:project/content-cloud/assets/:asset"
                    element={<Navigate to={`${this.props.location.pathname}/content${this.props.location.search}`} />}
                  />
                  <Route path="projects/:project/content-cloud/assets/:asset/:tab" element={<AssetEntryPage />} />

                  <Route
                    path="projects/:project/content-cloud/tags"
                    element={<TagCollectionPage byStatus={search.get('status') as null} />}
                  />
                  <Route
                    path="projects/:project/content-cloud/tags/:tags"
                    element={<Navigate to={`${this.props.location.pathname}/content${this.props.location.search}`} />}
                  />
                  <Route path="projects/:project/content-cloud/tags/:tags/:tab" element={<TagEntryPage />} />

                  <Route path="projects/:project/content-cloud/graphql-explorer" element={<GraphQlExplorer />} />
                  <Route path="projects/:project/content-cloud/rest-explorer" element={<RestExplorer />} />
                  <Route path="projects/:project/content-cloud/rest-explorer" element={<RestExplorer />} />

                  {/* Sync Core specific */}
                  <Route path="projects/:project/notifications" element={<ProjectDetail tab="notifications" />} />
                  <Route
                    path="projects/:project/dashboard"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) =>
                              appContext?.project?.id && appContext?.project?.id === params.project && appContext?.site?.id ? (
                                <Navigate
                                  to={`/projects/${appContext.project.id}/dashboard/${appContext.site.id}${this.props.location.search}`}
                                  replace
                                />
                              ) : (
                                <Dashboard />
                              )
                            }
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route path="projects/:project/dashboard/:site" element={<Dashboard />} />
                  <Route path="projects/:project/sites" element={<SiteList />} />
                  <Route
                    path="projects/:project/sites/:site"
                    element={<Navigate to={`${this.props.location.pathname}/stats${this.props.location.search}`} />}
                  />
                  <Route path="projects/:project/sites/:site/:tab" element={<SiteDetail />} />
                  <Route
                    path="projects/:project/updates"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) =>
                              appContext?.project?.id && appContext?.project?.id === params.project && appContext?.site?.id ? (
                                <Navigate
                                  to={`/projects/${appContext.project.id}/updates/${appContext.site.id}${this.props.location.search}`}
                                  replace
                                />
                              ) : (
                                <ContentDashboard activeTab="synchronizations" />
                              )
                            }
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route path="projects/:project/updates/:site" element={<ContentDashboard activeTab="synchronizations" />} />
                  <Route path="projects/:project/content/:site" element={<ContentDashboard />} />
                  <Route
                    path="projects/:project/content"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) =>
                              appContext?.project?.id && appContext?.project?.id === params.project && appContext?.site?.id ? (
                                <Navigate
                                  to={`/projects/${appContext.project.id}/content/${appContext.site.id}${this.props.location.search}`}
                                  replace
                                />
                              ) : (
                                <ContentDashboard />
                              )
                            }
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />

                  <Route
                    path="sites"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate to={`/projects/${appContext?.project?.id}/sites${this.props.location.search}`} replace />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="sites/:id"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithSite entityId={params?.id}>
                            {(site) => (
                              <Navigate
                                to={`/projects/${site?.project.getId()}/sites/${params?.id}${this.props.location.search}`}
                                replace
                              />
                            )}
                          </WithSite>
                        )}
                      </WithParams>
                    }
                  />

                  <Route
                    path="updates"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate to={`/projects/${appContext?.project?.id}/updates${this.props.location.search}`} replace />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate to={`/projects/${appContext?.project?.id}/content${this.props.location.search}`} replace />
                        )}
                      </WithAppContext>
                    }
                  />

                  {/* Content Cloud specific pages */}
                  <Route
                    path="content-cloud/content-model"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate
                            to={`/projects/${appContext?.project?.id}/content-cloud/content-model${this.props.location.search}`}
                            replace
                          />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/content-model/:type"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) => (
                              <Navigate
                                to={`/projects/${appContext?.project?.id}/content-cloud/content-model/${params.type}${this.props.location.search}`}
                                replace
                              />
                            )}
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route
                    path="content-cloud/content"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate
                            to={`/projects/${appContext?.project?.id}/content-cloud/content${this.props.location.search}`}
                            replace
                          />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/content/:content"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) => (
                              <Navigate
                                to={`/projects/${appContext?.project?.id}/content-cloud/content/${params.content}${this.props.location.search}`}
                                replace
                              />
                            )}
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route
                    path="content-cloud/content-user-data"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate
                            to={`/projects/${appContext?.project?.id}/content-cloud/content-user-data${this.props.location.search}`}
                            replace
                          />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/assets"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate to={`/projects/${appContext?.project?.id}/content-cloud/assets${this.props.location.search}`} replace />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/assets/:asset"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) => (
                              <Navigate
                                to={`/projects/${appContext?.project?.id}/content-cloud/assets/${params.asset}${this.props.location.search}`}
                                replace
                              />
                            )}
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route
                    path="content-cloud/tags"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate to={`/projects/${appContext?.project?.id}/content-cloud/tags${this.props.location.search}`} replace />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/tags/:tag"
                    element={
                      <WithParams>
                        {(params) => (
                          <WithAppContext>
                            {(appContext) => (
                              <Navigate
                                to={`/projects/${appContext?.project?.id}/content-cloud/tags/${params.tag}${this.props.location.search}`}
                                replace
                              />
                            )}
                          </WithAppContext>
                        )}
                      </WithParams>
                    }
                  />
                  <Route
                    path="content-cloud/rest-explorer"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate
                            to={`/projects/${appContext?.project?.id}/content-cloud/rest-explorer${this.props.location.search}`}
                            replace
                          />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/graphql-explorer"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate
                            to={`/projects/${appContext?.project?.id}/content-cloud/graphql-explorer${this.props.location.search}`}
                            replace
                          />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud/integration"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate
                            to={`/projects/${appContext?.project?.id}/content-cloud/integration${this.props.location.search}`}
                            replace
                          />
                        )}
                      </WithAppContext>
                    }
                  />
                  <Route
                    path="content-cloud"
                    element={
                      <WithAppContext>
                        {(appContext) => (
                          <Navigate to={`/projects/${appContext?.project?.id}/content-cloud${this.props.location.search}`} replace />
                        )}
                      </WithAppContext>
                    }
                  />

                  <Route path="subscriptions" element={<ContractList />} />
                  <Route path="subscriptions/:id" element={<ContractDetail />} />
                  <Route path="subscriptions/:contract/revisions/:id/upgrade" element={<ContractUpgrade />} />
                  <Route path="subscriptions/:contract/revisions/:id/renew" element={<ContractUpgrade />} />

                  <Route path="sync-cores" element={<SyncCoreList />} />
                  <Route path="sync-cores/:id" element={<SyncCoreDetail />} />

                  <Route path="get-started" element={<GetStartedInstructions />} />

                  <Route path="checkout" element={<Navigate to={'/'} replace />} />
                  <Route path="checkout/:id" element={<CheckoutPage />} />

                  <Route path="backend">
                    <Route path="" element={<Navigate to={'/backend/billing'} replace />} />

                    <Route path="settings" element={<BackendSettings />} />

                    <Route path="billing">
                      <Route path="" element={<Navigate to={'/backend/billing/customers'} replace />} />

                      <Route path="invoices/" element={<BackendInvoiceList />} />
                      <Route path="invoices/:status" element={<BackendInvoiceList />} />
                      <Route path="customers" element={<BackendCustomerList />} />
                      <Route path="customers/:id" element={<BackendCustomerDetail />} />
                      <Route path="customers/:id/contracts/add" element={<BackendAddContract />} />
                      <Route path="customers/:customerId/contracts/:id/add" element={<BackendAddContractRevision />} />
                      <Route path="contracts" element={<BackendContracts />} />
                      <Route path="contracts/:tab" element={<BackendContracts />} />
                      <Route path="contracts/:tab/:id" element={<BackendContracts />} />
                      <Route path="contracts/:id" element={<BackendContracts />} />
                    </Route>

                    <Route path="syndication">
                      <Route path="" element={<Navigate to={'/account/syndication/sites'} replace />} />

                      <Route path="projects" element={<BackendProjectList />} />
                      <Route path="sites" element={<BackendSiteList />} />
                      <Route path="sync-cores" element={<BackendSyncCoreList />} />
                      <Route path="logs" element={<BackendLogs />} />
                    </Route>
                  </Route>

                  <Route element={<NotFoundPage />} />
                </Routes>
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const RoutingContainer = withLocation<IExternalProps>(RoutingContainerClass);
