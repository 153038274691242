import React, { useMemo, useState } from 'react';
import { Permission } from '../shared-permissions';
import { ContentCloudComponentData, withContentCloud } from '../WithContentCloud';
import { TagEntry, LocaleEntry, RestClient } from '../RestClient';
import { CONTENT_CLOUD_API_VERSION, convertListResponse, getContentCloudSatelliteUrl } from '../content-cloud-helper';
import { Col, Row } from 'react-bootstrap';
import { getStyleColors, PagedList } from '../../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentCloudListPageLayout } from '../Layouts/ContentCloudListPageLayout';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons/faBarsSort';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { HeaderCol } from '@edgebox/react-components';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';

const ENTRIES_PER_PAGE = 10;
const FILTER_ENTRIES_PER_PAGE = 10;

interface Props extends ContentCloudComponentData {
  byStatus?: 'published' | 'draft' | null;
}

function TagCollectionPage({ environment, space, accessToken, contentCloud, byStatus }: Props) {
  const client = useMemo(
    () =>
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );
  const previewClient = useMemo(
    () =>
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'preview',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [localeEntry, setLocaleEntry] = useState<LocaleEntry | null>(null);

  const { primary, danger } = getStyleColors();

  return (
    <ContentCloudPageLayout>
      <ContentCloudListPageLayout
        filterHeader={
          <>
            <div className={`px-2 pb-3 border-bottom`}>
              <Link to={`?`} className="text-decoration-none text-black">
                <div className={`${!byStatus ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faBarsSort} className="me-2" />
                  All tags
                </div>
              </Link>
            </div>
            <div className={`px-2 py-3 border-bottom`}>
              <div className="text-muted small pe-2">Status</div>
              <Link to={`?status=published`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'published' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faGlobe} className="me-2" />
                  Published
                </div>
              </Link>
              <Link to={`?status=draft`} className="text-decoration-none text-black">
                <div className={`${byStatus === 'draft' ? 'fw-bold bg-primary text-white' : ''} py-2 px-3 rounded`}>
                  <FontAwesomeIcon icon={faLock} className="me-2" />
                  Draft
                </div>
              </Link>
            </div>
          </>
        }
        contentHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2">
            {byStatus === 'draft' ? 'Draft' : byStatus === 'published' ? 'Published' : 'All'} Tags
          </HeadlineWithBreadcrumbNavigation>
        }
      >
        <PagedList<TagEntry, { locale?: string; status?: string | null }>
          searchable
          filter={{
            status: byStatus,
          }}
          renderListHeader={() => (
            <Row>
              <HeaderCol xs={6}>Name</HeaderCol>
              <HeaderCol xs={2}>Taxonomy</HeaderCol>
              <HeaderCol xs={2}>Updated</HeaderCol>
              <HeaderCol xs={2}>Status</HeaderCol>
            </Row>
          )}
          renderFilters={(onChange) => (
            <>
              <Col xs={9}></Col>
              <Col xs={3}>
                <AsyncPaginate
                  isClearable={true}
                  isMulti={false}
                  loadOptions={async (search, lastOptions, additional: any) => {
                    const page = additional ? additional.page + 1 : 0;
                    const response = await client.localeCollection({
                      skip: page * FILTER_ENTRIES_PER_PAGE,
                      limit: FILTER_ENTRIES_PER_PAGE,
                      'sys.name': search,
                    });

                    return {
                      options: response.items,
                      hasMore: response.skip < response.total,
                      additional: { page },
                    };
                  }}
                  placeholder="Change Locale..."
                  value={localeEntry}
                  getOptionValue={(option) => option.sys.id}
                  getOptionLabel={(option) => option.sys.name!}
                  onChange={(option) => {
                    setLocaleEntry(option);
                    onChange('locale', option?.code);
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                />
              </Col>
            </>
          )}
          renderItem={(tagEntry) => (
            <Link
              to={`/content-cloud/tags/${tagEntry.sys.id}?locale=${localeEntry?.code ?? ''}`}
              className={'text-black ps-3 pe-1 pt-1 pb-1 d-block rounded bg-white mb-2 hover-shadow text-decoration-none'}
              key={tagEntry.sys.id}
            >
              <Row className="m-0 p-0">
                <Col xs={6} className="ms-0 me-0 pt-2">
                  <span className="fw-bold pt-1">{tagEntry.sys.name}</span>
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  <span className="text-light">—</span>
                </Col>
                <Col xs={2} className="ms-0 me-0 pt-2">
                  <EntryRelativeDate date={tagEntry.sys.versionCreatedAt} />
                </Col>
                <Col xs={1} className="ms-0 me-0 pt-2">
                  <EntryPublishedBadge sys={tagEntry.sys} />
                </Col>
                <Col xs={1} className="text-end fs-4 text-light pt-1">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Row>
            </Link>
          )}
          request={async (page, filter) => {
            const response = await convertListResponse(
              (filter?.status === 'published' ? client : previewClient).tagCollection({
                limit: ENTRIES_PER_PAGE,
                skip: page * ENTRIES_PER_PAGE,
                ...(filter
                  ? {
                      locale: filter.locale,
                    }
                  : {}),
                order: '-sys.versionCreatedAt',
                ...(filter?.status === 'draft'
                  ? {
                      'sys.localizationFirstPublishedAt[exists]': 'false',
                    }
                  : {}),
              }),
              ENTRIES_PER_PAGE
            );

            return response;
          }}
        />
      </ContentCloudListPageLayout>
    </ContentCloudPageLayout>
  );
}

export default withContentCloud(TagCollectionPage, [Permission.CONTENT_READ, Permission.PREVIEW, Permission.SPACE_READ]);
