import React, { useEffect, useMemo, useState } from 'react';
import { IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { AssetEntry, ExternalEntryLinkType, getImageUrl, RestClient } from '../RestClient';
import { ContentCloudComponentData, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, getFileSizeDisplay, getNumberDisplay } from '../content-cloud-helper';
import { Permission } from '../shared-permissions';
import { Alert, Image, Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton, IconButton, LeftRightContainer, LoadingBar } from '@edgebox/react-components';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { Link } from 'react-router-dom';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';
import { ContentEntryPropertyWrapper, StringPropertyValue } from './Components/ContentEntryProperties';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';

interface Props extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp, ILocationProp, ContentCloudComponentData {
  params: {
    asset: string;
    tab: string;
  };
}

function AssetEntryPageComponent({ accessToken, contentCloud, environment, space, params, location }: Props) {
  const client = useMemo(
    () =>
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [sidebarTab, setSidebarTab] = useState('general');
  const [contentTab, setContentTab] = useState(params.tab);

  const search = new URLSearchParams(location.search);
  const locale = search.get('locale');
  const [assetEntry, setAssetEntry] = useState<AssetEntry | null>(null);
  useEffect(() => {
    setAssetEntry(null);

    client.assetEntry({ id: params.asset, ...(locale ? { locale } : {}) }).then((AssetEntry) => setAssetEntry(AssetEntry));
  }, [client, params.asset, locale]);

  const json = useMemo(() => JSON.stringify(assetEntry, null, 2), [assetEntry]);

  if (!assetEntry) {
    return (
      <ContentCloudPageLayout>
        <LoadingBar />
      </ContentCloudPageLayout>
    );
  }

  const source = assetEntry.sys.externalLinks?.find((c) => c.linkType === ExternalEntryLinkType.Source);

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={<HeadlineWithBreadcrumbNavigation className="ms-2">{assetEntry.sys.name}</HeadlineWithBreadcrumbNavigation>}
        sidebar={
          <Tabs
            id={'sidebar-tabs'}
            className={'mb-5'}
            activeKey={sidebarTab}
            onSelect={(k: string | null) => setSidebarTab(k ?? 'general')}
          >
            <Tab eventKey={'general'} title={'General'}>
              <div className="p-3">
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Status</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span className="text-muted">Current</span>}
                    right={<EntryPublishedBadge sys={assetEntry.sys} />}
                  />
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Publication</div>
                  {!assetEntry.sys.isPublished && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Draft saved at'}
                      right={<EntryRelativeDate date={assetEntry.sys.versionCreatedAt} />}
                    />
                  )}
                  {assetEntry.sys.publishedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Published at'}
                      right={<EntryRelativeDate date={assetEntry.sys.publishedAt} />}
                    />
                  )}
                  {assetEntry.sys.firstPublishedAt && assetEntry.sys.firstPublishedAt !== assetEntry.sys.versionCreatedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'First published at'}
                      right={<EntryRelativeDate date={assetEntry.sys.firstPublishedAt} />}
                    />
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey={'source'} title={'Source'}>
              <div className="p-3">
                {source ? (
                  <div className="mb-4">
                    <div className="small fw-bold border-bottom text-muted pb-1">Canonical URL</div>
                    {source.canonicalUrl ? (
                      <Link to={source.canonicalUrl} target="_blank" className="text-truncate d-block">
                        {source.domain}
                      </Link>
                    ) : (
                      <em>Not provided.</em>
                    )}
                  </div>
                ) : (
                  <Alert variant="light">This entry doesn't include any source information.</Alert>
                )}
              </div>
            </Tab>
            {/*<Tab eventKey={'links'} title={'Links'}>
                    <div className="p-2">Links</div>
                  </Tab>*/}
            <Tab eventKey={'info'} title={'Info'}>
              <div className="p-3">
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Asset</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={'File name'}
                    right={<div className="text-truncate">{assetEntry.fields.fileName}</div>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={'Type'}
                    right={<div className="text-truncate">{assetEntry.fields.mimeTypeGroup}</div>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={'File size'}
                    right={<div className="text-truncate">{getFileSizeDisplay(assetEntry.fields.size)}</div>}
                  />
                  {assetEntry.fields.details?.image && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Width / Height'}
                      right={
                        <div className="text-truncate">
                          {getNumberDisplay(assetEntry.fields.details.image.width)} x{' '}
                          {getNumberDisplay(assetEntry.fields.details.image.height)}
                        </div>
                      }
                    />
                  )}
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Entry</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>ID</span>}
                    right={
                      <span>
                        {assetEntry.sys.id} <CopyToClipboardButton name="copy-entry-id" text={assetEntry.sys.id} buttonText="" buttonOnly />
                      </span>
                    }
                  />
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Version</div>
                  <LeftRightContainer className="pt-2" left={<span>Entry</span>} right={<span>{assetEntry.sys.entryVersion}</span>} />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Localization</span>}
                    right={<span>{assetEntry.sys.localizationVersion}</span>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Version ID</span>}
                    right={<CopyToClipboardButton name="copy-version-id" text={assetEntry.sys.versionId} buttonText=" Copy" buttonOnly />}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>File hash</span>}
                    right={<CopyToClipboardButton name="copy-file-hash" text={assetEntry.fields.hash} buttonText=" Copy" buttonOnly />}
                  />
                  {assetEntry.fields.customVersionId && (
                    <LeftRightContainer
                      className="pt-2"
                      left={<span>Custom version ID</span>}
                      right={
                        <CopyToClipboardButton
                          name="copy-file-custom-version-id"
                          text={assetEntry.fields.customVersionId}
                          buttonText=" Copy"
                          buttonOnly
                        />
                      }
                    />
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>
        }
      >
        <Tabs id={'content-tabs'} className={'mb-5'} activeKey={contentTab} onSelect={(k: string | null) => setContentTab(k ?? 'content')}>
          <Tab eventKey={'content'} title={'Content'} className="p-3">
            <ContentEntryPropertyWrapper label="Title">
              <StringPropertyValue>{assetEntry.name}</StringPropertyValue>
            </ContentEntryPropertyWrapper>

            {assetEntry.sys.description && (
              <ContentEntryPropertyWrapper label="Description">
                <StringPropertyValue>{assetEntry.sys.description}</StringPropertyValue>
              </ContentEntryPropertyWrapper>
            )}

            <ContentEntryPropertyWrapper label="File">
              {assetEntry.fields.embedUrl ? (
                assetEntry.fields.imageUrl ? (
                  <div>
                    <Image src={getImageUrl(assetEntry.fields.imageUrl, { height: 300, fit: 'scale-down', format: 'webp' })} height={300} />
                  </div>
                ) : (
                  <div>
                    <embed src={assetEntry.fields.embedUrl} width="100%" height="300" type={assetEntry.fields.mimeType} />
                  </div>
                )
              ) : null}
              {assetEntry.fields.downloadUrl && (
                <div className="mt-2">
                  <IconButton to={assetEntry.fields.downloadUrl} target="_blank" variant="light" icon={faDownload}>
                    Download
                  </IconButton>
                </div>
              )}
            </ContentEntryPropertyWrapper>
          </Tab>
          {/*<Tab eventKey={'links'} title={'Links'}>
                    Links
                  </Tab>
                  <Tab eventKey={'tags'} title={'Tags'}>
                    Tags
                  </Tab>
                  <Tab eventKey={'media'} title={'Media'}>
                    Media
                  </Tab>*/}
          <Tab eventKey={'json'} title={'JSON'} className="p-3">
            <code className="border rounded border-light p-2 d-block mx-2 mb-2 position-relative">
              <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                <CopyToClipboardButton name="copy-json" buttonText=" Copy" text={json} buttonOnly />
              </div>
              <pre>{json}</pre>
            </code>
          </Tab>
        </Tabs>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}

export const AssetEntryPage = withParams<{}, { site: string; tab: string }>(
  withLocationAndNavigate<any>(
    withAppContext<any>(
      withContentCloud(AssetEntryPageComponent, [
        Permission.CONTENT_READ,
        Permission.SPACE_READ,
        Permission.PREVIEW,
        Permission.ASSET_READ_FILE,
        Permission.EXTERNAL_LINK_READ,
      ])
    )
  )
);
