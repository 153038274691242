import React, { useContext, useState } from 'react';
import { Alert, Container, Modal } from 'react-bootstrap';
import { ContentBox } from '../../../Shared/ContentBox';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { getContentCloudSatelliteUrl } from '../content-cloud-helper';
import { ContentCloudComponentData, withContentCloud } from '../WithContentCloud';
import { Permission } from '../shared-permissions';
import { CopyToClipboardButton, IconButton } from '@edgebox/react-components';
import { faCircleA } from '@fortawesome/pro-light-svg-icons/faCircleA';
import { ApolloSandbox } from '@apollo/sandbox/react';
import { AppContext } from '../../../../common/contexts/AppContext';

interface Props extends ContentCloudComponentData {}

function GraphQlExplorer({ accessToken, contentCloud, environment, space }: Props) {
  const appContext = useContext(AppContext);
  const { project } = appContext;

  const graphQlUrlDev = getContentCloudSatelliteUrl(contentCloud, {
    service: 'dev',
    environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
    api: 'graphql',
  });
  const graphQlUrlLive = getContentCloudSatelliteUrl(contentCloud, {
    service: 'live',
    environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
    api: 'graphql',
  });
  const graphQlUrlCdn = getContentCloudSatelliteUrl(contentCloud, {
    service: 'cdn',
    environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
    api: 'graphql',
  });
  const graphQlUrlPreview = getContentCloudSatelliteUrl(contentCloud, {
    service: 'preview',
    environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
    api: 'graphql',
  });

  const [showExplorer, setShowExplorer] = useState(false);

  return (
    <Container>
      <Modal show={showExplorer} fullscreen onHide={() => setShowExplorer(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{project?.name}: Apollo Sandbox</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="position-relative w-100 h-100 p-0 overflow-hidden">
            <ApolloSandbox
              className="h-100 w-100"
              initialEndpoint={graphQlUrlDev}
              handleRequest={(endpointUrl, options) => {
                return fetch(endpointUrl, {
                  ...options,
                  headers: {
                    ...options.headers,
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
              }}
              hideCookieToggle
              initialState={{
                includeCookies: false,
                pollForSchemaUpdates: true,
                document: ``,
                variables: {},
              }}
              endpointIsEditable={false}
            />
            <div
              className="position-absolute bg-white text-muted ps-3 pt-2 text-truncate"
              style={{ left: '0', right: '0', top: '0', height: '48px' }}
            >
              We are using{' '}
              <a href="https://www.apollographql.com/" target="_blank" rel="noreferrer">
                Apollo
              </a>{' '}
              for providing the GraphQL explorer. The explorer is subject to Apollo's privacy policy and terms of service.
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <HeadlineWithBreadcrumbNavigation>GraphQL explorer</HeadlineWithBreadcrumbNavigation>

        <ContentBox>
          <h2 className="mt-3">Apollo</h2>
          <Alert variant="light">
            <p>
              We are using{' '}
              <a href="https://www.apollographql.com/" target="_blank" rel="noreferrer">
                Apollo
              </a>{' '}
              for providing the GraphQL explorer. The explorer linked below is subject to Apollo's privacy policy and terms of service.
            </p>
            <p>
              <IconButton variant="primary" icon={faCircleA} onClick={() => setShowExplorer(true)}>
                Open in Apollo
              </IconButton>
            </p>
          </Alert>

          <h2 className="mt-5">Endpoints</h2>
          <div className="mb-4">
            <div className="small fw-bold border-bottom text-muted pb-1">Live GraphQL endpoint</div>
            <div className="pt-1">
              <CopyToClipboardButton name="copy-graphql-url-live" text={graphQlUrlLive} buttonText="" />
            </div>
          </div>

          <div className="mb-4">
            <div className="small fw-bold border-bottom text-muted pb-1">CDN GraphQL endpoint</div>
            <div className="pt-1">
              <CopyToClipboardButton name="copy-graphql-url-cdn" text={graphQlUrlCdn} buttonText="" />
            </div>
          </div>

          <div className="mb-4">
            <div className="small fw-bold border-bottom text-muted pb-1">Preview GraphQL endpoint</div>
            <div className="pt-1">
              <CopyToClipboardButton name="copy-graphql-url-preview" text={graphQlUrlPreview} buttonText="" />
            </div>
          </div>
        </ContentBox>
      </div>
    </Container>
  );
}

export default withContentCloud(GraphQlExplorer, [
  Permission.SPACE_READ,
  Permission.CONTENT_READ,
  Permission.ASSET_READ_FILE,
  Permission.EXTERNAL_LINK_READ,
  Permission.CONTENT_TYPE_READ,
  Permission.DEVELOPER,
]);
