import React from 'react';
import { Container } from 'react-bootstrap';

type Props = {
  filterHeader?: React.ReactNode;
  filterBody?: React.ReactNode;
  contentHeader?: React.ReactNode;
  children?: React.ReactNode;
};

export function ContentCloudListPageLayout({ filterHeader, filterBody, contentHeader, children }: Props) {
  return (
    <div className={`d-flex flex-row justify-content-center align-items-stretch`}>
      <div className="border-right p-3 pe-4 pt-4 flex-shrink-0 flex-grow-0" style={{ width: '300px', height: 'calc(100vh - 60px)' }}>
        <div className="h-100 d-flex flex-column">
          <div className="flex-grow-0 flex-shrink-0">{filterHeader}</div>
          <div className="flex-grow-1 flex-shrink-1 overflow-auto">{filterBody}</div>
        </div>
      </div>
      <div className="flex-shrink-1 flex-grow-1 pt-4" style={{ height: 'calc(100vh - 60px)' }}>
        <div className="h-100 d-flex flex-column">
          <Container className="flex-grow-0 flex-shrink-0">{contentHeader}</Container>
          <Container className="flex-grow-1 flex-shrink-1 overflow-auto">{children}</Container>
        </div>
      </div>
    </div>
  );
}
