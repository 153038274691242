import React from 'react';
import { Container } from 'react-bootstrap';
import { ApiComponent, IApiComponentState } from '../../../../common/index';
import { ContentBox } from '../../../Shared/ContentBox';
import { GetStartedInstructions } from '../../GetStartedInstructions';
import { IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { propertyChangeReload } from '../../../../common/helpers/propertyChangeReload';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { withParams } from '../../../RouterHelper';

interface IProps extends IAppContextProp, JSX.IntrinsicAttributes {
  params: {
    project?: string;
    site?: string;
  };
}

interface IState extends IApiComponentState {}

class ContentCloudIntroductionClass extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    return (
      <Container>
        <div>
          <HeadlineWithBreadcrumbNavigation>Welcome to Content Sync!</HeadlineWithBreadcrumbNavigation>

          <ContentBox className={'pb-4'}>
            <GetStartedInstructions />
          </ContentBox>
        </div>
      </Container>
    );
  }
}

export const ContentCloudIntroduction = withParams<{}, { site?: string; project?: string }>(
  withAppContext(propertyChangeReload(ContentCloudIntroductionClass, (props) => props.appContext.projectKey))
);
