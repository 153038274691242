import { ProjectStatus } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { SyncCoreDataDefinitionsEnumValue } from '../../Localization/SyncCoreDataDefinitionsEnumValue';

interface IProps {
  status?: ProjectStatus;
}

export class ProjectStatusBadge extends React.Component<IProps, {}> {
  render(): React.ReactElement {
    const { status } = this.props;

    const classes = 'opacity-3';

    const variants = {
      [ProjectStatus.Active]: 'success',
      [ProjectStatus.Inactive]: 'dark',
    };

    return (
      <Badge bg={variants[status || ProjectStatus.Active]} className={classes}>
        <SyncCoreDataDefinitionsEnumValue enumName={'RemoteServiceStatus'} keyName={status || ProjectStatus.Active} />
      </Badge>
    );
  }
}
