import React, { useMemo, useState } from 'react';
import { ContentEntry, ContentTypeEntry, ContentTypePropertyEntry } from '../../RestClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { Link } from 'react-router-dom';
import { ButtonLink } from '@edgebox/react-components';

type RenderSettings = {
  allContentTypes: ContentTypeEntry[];
  locale: string;
};
type Props = RenderSettings & {
  id?: string;
  contentEntry: ContentEntry;
};

function getClosestId(element: HTMLElement) {
  if (element.id) {
    return element.id;
  }
  if (element.parentElement && element.parentElement !== window.document.body) {
    return getClosestId(element.parentElement);
  }
  return null;
}

export function StringPropertyValue({ children: value }: { children: string }) {
  if (!!value) {
    return (
      <div className="border rounded border-light py-1 px-2">
        <pre className="m-0">{value}</pre>
      </div>
    );
  }
  return <div className="py-1 px-2 text-danger">""</div>;
}

export function IndividualContentPropertyValue({
  property,
  settings,
  value,
  id,
  isArrayItem,
}: {
  property: ContentTypePropertyEntry;
  value: any;
  id: string;
  settings: RenderSettings;
  isArrayItem?: boolean;
}) {
  const [expanded, setExpanded] = useState(false);

  if (property.type === 'Boolean') {
    if (value) {
      return (
        <span className="text-success">
          <FontAwesomeIcon icon={faCheck} /> Yes
        </span>
      );
    }
    return (
      <span className="text-danger">
        <FontAwesomeIcon icon={faTimes} /> No
      </span>
    );
  }

  if (property.type === 'Int' || property.type === 'Float') {
    return <span className="text-warning">{value}</span>;
  }

  if (property.type === 'String') {
    return <StringPropertyValue>{value}</StringPropertyValue>;
  }

  if (property.type === 'Date') {
    return <span className="border rounded border-light py-1 px-2">{value}</span>;
  }

  if (property.type === 'JSON') {
    return (
      <code>
        <pre>{JSON.stringify(value, null, 2)}</pre>
      </code>
    );
  }

  // Embedded content
  if (value.fields) {
    return (
      <div
        className={`cursor-pointer ${isArrayItem ? 'p-2' : 'p-3 border rounded border-light'}`}
        id={id}
        onClick={(e) => (getClosestId(e.target as HTMLElement) === id ? setExpanded(!expanded) : undefined)}
      >
        <div className="d-flex flex-row">
          <div className="flex-grow-1 flex-shrink-1">
            <span className="fw-bold">{settings.allContentTypes.find((c) => c.id === value.sys.contentType?.sys.id)?.name}</span>
            <span className="ps-2 text-italic text-truncate">{value.sys.name}</span>
          </div>
          <div className="flex-grow-0 flex-shrink-0 text-light">
            {value.sys.id && (
              <ButtonLink variant="light" className="py-0" to={`/content-cloud/content/${value.sys.id}?locale=${settings.locale}`}>
                Open
              </ButtonLink>
            )}
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
          </div>
        </div>
        {expanded ? (
          <div className="mt-3">
            <ContentEntryProperties {...settings} contentEntry={value} id={id} />
          </div>
        ) : null}
      </div>
    );
  }

  if (value.sys.type === 'Link') {
    if (value.sys.linkType === 'Content') {
      return <Link to={`/content-cloud/content/${value.sys.id}?locale=${settings.locale}`}>Content {value.sys.id}</Link>;
    }
  }

  console.log('Unknown property', property.type, value);

  return (
    <span className="text-dark">
      <FontAwesomeIcon icon={faQuestion} /> Unknown
    </span>
  );
}
export function ContentPropertyValue({
  property,
  settings,
  value,
  id,
}: {
  property: ContentTypePropertyEntry;
  value: any;
  settings: RenderSettings;
  id: string;
}) {
  if (property.isArray) {
    return value.map((c: any, i: number) => (
      <div
        className={`p-2 border border-light ${i === 0 ? 'rounded-top' : ''} ${i === value.length - 1 ? 'rounded-bottom' : 'border-bottom-0'}`}
        key={i}
      >
        <IndividualContentPropertyValue property={property} value={c} settings={settings} id={`${id}-${i}`} isArrayItem />
      </div>
    ));
  }

  return <IndividualContentPropertyValue property={property} value={value} settings={settings} id={id} />;
}

export function ContentEntryPropertyWrapper({ label, children }: { label: string; children: React.ReactNode }) {
  return (
    <div className="mb-4 border-start border-2 px-3 pt-2 pb-1">
      <label>{label}</label>
      <div className="pt-1">{children}</div>
    </div>
  );
}

export function ContentEntryProperties({ allContentTypes, contentEntry, locale, id }: Props) {
  const contentType = allContentTypes.find((c) => c.id === contentEntry.sys.contentType?.sys.id);

  const properties = useMemo(() => [...(contentType?.properties.sort((a, b) => a.name.localeCompare(b.name)) ?? [])], [contentType]);

  if (!contentType) {
    return null;
  }

  return (
    <div>
      {properties.map((property) => {
        const value = contentEntry.fields[property.machineName];
        if (value === null || value === undefined) {
          return null;
        }

        return (
          <ContentEntryPropertyWrapper key={property.id} label={property.name}>
            <ContentPropertyValue
              property={property}
              value={value}
              settings={{ locale, allContentTypes }}
              id={id ? `${id}-${property.id}` : `${contentEntry.sys.id}-${property.id}`}
            />
          </ContentEntryPropertyWrapper>
        );
      })}
    </div>
  );
}
