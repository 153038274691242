import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { ContentBox } from '../../../Shared/ContentBox';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';

export function ContentUserDataTypeCollectionPage() {
  return (
    <Container>
      <div>
        <HeadlineWithBreadcrumbNavigation>Content user data</HeadlineWithBreadcrumbNavigation>

        <Alert className={'mt-4'} variant="light">
          Content user data is not enabled for this space.
        </Alert>
      </div>
    </Container>
  );
}
