import { InternalId } from '@edgebox/data-definition-kit';
import { CopyToClipboardButton } from '@edgebox/react-components';
import React from 'react';
import { Button } from 'react-bootstrap';
import { ApiComponent, IApiComponentState } from '../../../services/ApiComponent';

interface IProps {
  entityId: InternalId;
}

interface IState extends IApiComponentState {
  secret?: string;
  loading?: boolean;
}

const REVEAL_SECONDS = 10;

export class ViewSyncCoreSecret extends ApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  timeout: number | undefined = undefined;
  componentWillUnmount() {
    super.componentWillUnmount();

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  render(): React.ReactElement {
    const { entityId } = this.props;
    const { secret, loading } = this.state;

    const reveal = async () => {
      this.setState({
        loading: true,
      });

      try {
        const response = await this.api.syndication.remoteServices.getSecret(entityId);

        this.timeout = setTimeout(() => {
          this.setState({
            secret: undefined,
          });
        }, REVEAL_SECONDS * 1_000) as any;

        this.setState({
          secret: response.secret,
          loading: false,
        });
      } catch (e) {
        this.setState({
          loading: false,
        });
        throw e;
      }
    };

    return (
      <>
        {secret ? (
          <CopyToClipboardButton text={secret} name={'secret'} />
        ) : (
          <Button disabled={loading} variant={'light'} onClick={reveal} className={'ps-0 pe-0'}>
            View
          </Button>
        )}
      </>
    );
  }
}
