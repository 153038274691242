export enum Permission {
  // Frontend
  CONTENT_READ = 'permission:content:read', // Grants read access to tags and assets.
  CONTENT_TYPE_READ = 'permission:content-type:read', // Grants read access to tags and assets.

  ASSET_READ_FILE = 'permission:asset:read:file', // Can be scoped to include specific assets only.

  // User data, always scoped to the current user.
  USER_DATA_READ = 'permission:user-data:read',
  USER_DATA_WRITE = 'permission:user-data:write',

  // External content links
  EXTERNAL_LINK_READ = 'permission:external-link:read',
  EXTERNAL_LINK_WRITE = 'permission:external-link:write',
  // Scoped access, e.g. if only the canonical URL is relevant to visitors.
  /*EXTERNAL_LINK_READ_CANONICAL_URL = "permission:external-link:read:canonical-url",
  EXTERNAL_LINK_READ_PRETTY_URL = "permission:external-link:read:pretty-url",
  EXTERNAL_LINK_READ_EDIT_URL = "permission:external-link:read:edit-url",
  EXTERNAL_LINK_READ_DELETE_URL = "permission:external-link:read:delete-url",
  EXTERNAL_LINK_READ_VERSION_URL = "permission:external-link:read:version-url",
  EXTERNAL_LINK_READ_LOCALIZATION_URL = "permission:external-link:read:localization-url",*/

  // Grants preview access to all entities that are allowed to be read.
  PREVIEW = 'permission:preview',

  // Grants access to dev GraphQL + dev REST interfaces that have introspection enabled and a UI.
  DEVELOPER = 'permission:developer',

  // Grants read access to space and all related locales + environments.
  SPACE_READ = 'permission:space:read',

  // Management / backend.
  ORGANIZATION_READ = 'permission:organization:read',
  ORGANIZATION_WRITE = 'permission:organization:write',

  // Sync Core
  SPACE_WRITE = 'permission:space:write', // Grants write access to locales + environments.
  CONTENT_TYPE_WRITE = 'permission:content-type:write', // Grants write access to content type properties.
  CONTENT_WRITE = 'permission:content:write', // Grants write access to tags and assets.

  CLIENT_WRITE = 'permission:client:write',
  CLIENT_READ = 'permission:client:read', // Does NOT grant access to read secrets.
  CLIENT_SECRET = 'permission:client:secret', // Grants access to read secrets explicitly.
}

export type AllowedPublicPermissions =
  | Permission.CONTENT_READ
  | Permission.CONTENT_TYPE_READ
  | Permission.ASSET_READ_FILE
  | Permission.EXTERNAL_LINK_READ
  | Permission.SPACE_READ;

export const ANY_RESOURCE = '*';

export const CLIENT_ID_ADMIN = 'admin';
export const CLIENT_ID_ACCESS_ALL = 'access-all';
export const CLIENT_ID_PUBLIC = 'public';

export interface IUserJwtPayloadBase {
  clientId: string;
  userId?: string;
  userDataContentTypes?: string[];
  permissions: Permission[];
}

export interface ISingleSpaceUserJwtPayload extends IUserJwtPayloadBase {
  spaceId: string;
  environmentIds: string[];
}

export interface IAdminUserJwtPayload extends IUserJwtPayloadBase {
  allOrganizations: true;
  allSpaces: true;
}

export interface IEdgeUserJwtPayload extends IUserJwtPayloadBase {
  spaceId?: null;
  environmentIds?: null;
  allOrganizations?: false;
  allSpaces?: false;
}

export type IUserJwtPayload = ISingleSpaceUserJwtPayload /*| IMultipleSpacesUserJwtPayload*/ | IAdminUserJwtPayload;
