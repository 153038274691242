import React, { useMemo } from 'react';
import moment from 'moment';

type Props = {
  date: string;
};

export function EntryRelativeDate({ date }: Props) {
  const now = useMemo(() => moment(), []);
  const then = useMemo(() => moment(date), [date]);

  return <span>{then.from(now)}</span>;
}
